import { GalleryLikeEventArg, GalleryLikeEventType } from './../../../../core/service/events/gallery-like.event';
import { GalleryImageLikeState } from './../../../../core/model/galleryLike';
import { AuthService } from './../../../../core/service/auth.service';
import { ServiceBusEvent } from './../../../../core/service/events/service-bus.event';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { Gallery } from './../../../../core/model/gallery';
import { GalleryLikeService } from './../../../../core/service/crud/impl/galleryLike.service';
import { Component, OnInit, Input, TemplateRef, ViewChild, Output, EventEmitter, AfterViewInit, ElementRef } from '@angular/core';

@Component({
  selector: 'of-gallery-item',
  templateUrl: './gallery-item.component.html',
  styleUrls: ['./gallery-item.component.scss'],
  providers: [
    GalleryLikeService
  ]
})
export class GalleryItemComponent implements OnInit {

  @Input() public item: Gallery;
  @Output() selected = new EventEmitter();
  @Output() userSelected = new EventEmitter();

  length = 0;
  showInfo = false;
  isLoadingLike = false;
  imageToLoad = new Image();

  constructor(
    private serviceBusEvent: ServiceBusEvent,
    private gallerLikeService: GalleryLikeService,
    private authService: AuthService
  ) { }
  
  ngOnInit(): void {
    this.imageToLoad.src = this.item.image.url;
  }
  

  itemClicked(): void {
    this.selected.emit(this.item);
  }

  userClicked(): void {
    this.userSelected.emit({authorId: this.item.author.id, gallery: this.item});
  }

  async likePrompt(item: Gallery): Promise<void> {

    if (this.isLoadingLike == true) { return; }

    this.isLoadingLike = true;
    const result = await this.gallerLikeService.togglePrompt(item, item.likes[0]);
    if (result.result != 0) {
      this.serviceBusEvent.next(new GalleryLikeEventArg(GalleryLikeEventType.TOGGLETHUMSBS_NONE, result.identifier, result.state));
    }
    this.isLoadingLike = false;
  }


}
