import {UserService} from './../../../core/service/crud/impl/user.service';
import {User} from './../../../core/model/user';
import {OntologyInputComponent} from './../../../core/components/ontology-form/ontology-input.component';
import {TrackService} from './../../../core/service/crud/impl/track.service';
import {KeyValue} from './../../../core/model/ontology/model';
import {Helper} from './../../../core/logic/helper';
import {PermissionService} from './../../../core/service/permission.service';
import {AfterContentInit, AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MissionAssignmentService} from '../../../core/service/crud/impl/mission-assignment.service';
import {MissionStepService} from '../../../core/service/crud/impl/mission-step.service';
import {AuthService} from '../../../core/service/auth.service';
import {ActivatedRoute, Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {MissionStep} from '../../../core/model/mission-step';
import {MissionAssignment} from '../../../core/model/mission-assignment';
import {FilterOperator} from '../../../core/service/crud/search.service';
import {PaginateCriteria} from '../../../core/service/crud/paginate.service';
import {NotificationService} from '../../../core/service/notification.service';
import {MissionStepImageService} from '../../../core/service/crud/impl/mission-step-image.service';
import {MissionStepImage} from '../../../core/model/mission-step-image';
import {MissionService} from '../../../core/service/crud/impl/mission.service';

@Component({
    selector: 'of-mission-assignment-page',
    templateUrl: './mission-assignment-page.component.html',
    styleUrls: ['./mission-assignment-page.component.scss'],
    providers: [
        MissionStepService,
        MissionAssignmentService,
        PermissionService,
        MissionStepImageService,
        TrackService,
        UserService,
        MissionService
    ]
})
export class MissionAssignmentPageComponent implements OnInit, OnDestroy {

    @ViewChild('config')
    public config: OntologyInputComponent;

    subscriptions: Subscription[] = [];
    public isLoggedIn = false;
    public isLoading = true;
    public missionStep: MissionStep;
    public assignment: MissionAssignment;
    public unlockingAssignment: MissionAssignment;
    public missionStepId: string;
    public missionStepIsLocked = true;
    public selectedAssignmentValuation: KeyValue<any>;

    public selectedAssignmentImages: MissionStepImage[];

    public hasStepError = false;

    public isLoadingSubmissionSection = false;
    public showSubmissionPage = false;
    public isStepLoading = false;

    public hasError = false;
    public errorMessage;

    constructor(
        public authService: AuthService,
        public missionStepService: MissionStepService,
        public missionAssignmentService: MissionAssignmentService,
        private notificationService: NotificationService,
        private route: ActivatedRoute,
        private router: Router,
        public permissionService: PermissionService,
        public missionStepImageService: MissionStepImageService,
        public trackService: TrackService,
        private missionService: MissionService,
    ) {
    }

    ngOnInit(): void {
        this.isLoggedIn = this.authService.isLoggedIn();
        this.listenForId();
    }

    listenForId(): void {
        const sub = this.route.paramMap.subscribe({
            next: async (data) => {

                this.isLoading = true;
                this.missionStepId = data.get('id');
                await this.getMissionStep();
                this.isLoading = false;
                this.showSubmissionPage = false;
                if (this.isLoggedIn) {
                    await this.getMissionAssignment();
                }
                this.showSubmissionPage = true;
            }
        });

        this.subscriptions.push(sub);
    }

    async getMissionStep(): Promise<void> {
        const canViewHiddenMission = this.missionService.canViewHiddenMission();
        const criteria = new PaginateCriteria(1, 1);
        criteria.include = 'mission,unlockingStep';
        criteria.addFilter('missionStep.id', FilterOperator.EQUALS, this.missionStepId);

        try {
            this.missionStep = await this.missionStepService.findRecord(this.missionStepId, criteria).toPromise();

            if (this.missionStep.hidden && !canViewHiddenMission) {
                await this.router.navigateByUrl('/missions');
                return;
            }

        } catch (e) {
            this.notificationService.notify('Mission step not found');
            await this.router.navigate(['/missions']);
            return;
        }

        this.selectedAssignmentImages = await this.missionStepImageService.findByMissionStepId(this.missionStep.id);
    }

    async getMissionAssignment(): Promise<void> {
        const userId = this.authService.getUserId();

        // Get all the ids required to get the assignments
        const missionStepIds = [];
        missionStepIds.push(this.missionStepId);

        if (this.missionStep.unlockingStep) {
            missionStepIds.push(this.missionStep.unlockingStep.id);
        }

        const criteria = new PaginateCriteria(2, 1);
        criteria.include = 'missionStep';
        criteria.addFilter('missionAssignment.userId', FilterOperator.EQUALS, userId);
        criteria.addFilter('missionAssignment.missionStepId', FilterOperator.IN, `(${missionStepIds.join(',')})`);
        const data = (await this.missionAssignmentService.findAll(criteria).toPromise());


        this.assignment = data.getModels().find(x => x.missionStep.id === this.missionStepId);

        this.unlockingAssignment = data.getModels().find(x => x.missionStep.id !== this.missionStepId);

        this.missionStepIsLocked = Helper.missionIsLocked(this.missionStep, this.unlockingAssignment);

        try {
            this.selectedAssignmentValuation = (this.assignment?.content) ? JSON.parse(this.assignment?.content) : {};
        } catch (e) {
            this.selectedAssignmentValuation = {};
        }
    }

    private async setTracking(): Promise<void> {

        const selectedTrackURL = await this.trackService.gen({id: this.assignment.id, type: 'missionAssignment'});

        const longDescription = this.missionStep?.longDescription;

        if (longDescription) {
            this.missionStep.longDescription = longDescription.replace(
                '{url}',
                `<a target="_blank" href="${selectedTrackURL}">${selectedTrackURL}</a>`);
        }
    }

    public async setForSubmission() {
        if (!this.assignment) {
            this.isLoadingSubmissionSection = true;
            this.assignment = await this.missionAssignmentService.findOrCreateAssignment(this.missionStep.mission, this.missionStep);
            this.isLoadingSubmissionSection = false;
        }
    }

    private async showOntologyFormErrors() {
        const formGroup = this.config.formGroup;
        Object.keys(formGroup.controls).forEach(key => {
            if (formGroup.controls[key].errors) {
                formGroup.controls[key].markAsTouched();
            }
        });

        this.isStepLoading = false;
        this.hasStepError = true;
    }

    public async submitAssignment() {
        if (!this.isLoggedIn) {
            return;
        }

        if (this.missionStep.hidden) {
            return;
        }

        await this.setForSubmission();

        this.isStepLoading = true;
        this.hasStepError = false;

        if (this.config) {
            const valid = this.config?.formGroup.valid;
            if (!valid) {
                await this.showOntologyFormErrors();
                return;
            }
            const value = await this.config?.getValue();
            this.assignment.content = JSON.stringify(value);
        }
        this.assignment.status = 'PENDING';

        await this.missionAssignmentService.updateRecord(this.assignment).toPromise();

        this.assignment = await this.missionAssignmentService.findOrCreateAssignment(this.missionStep.mission, this.missionStep);

        this.notificationService.notify('Proof has been submitted')
        this.isStepLoading = false;
        await this.setTracking();
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(x => x.unsubscribe());
    }
}
