<ng-container *ngIf="form">
    <div class="of-upload of-upload--field"
         [class.has-resource]="base64" *ngIf="column">
        <div class="of-upload__drag of-upload__inner">
            <div class="file-upload-image" *ngIf="base64">
                <figure [ngClass]="figureCss" class="upload-image">
                    <img [ngClass]="imageCss" alt="..."
                         [src]="base64"/>
                </figure>

                <button (click)="removeResource(column)"
                        *ngIf="base64"
                        class="of-upload--clear of-button">
                    <i class="icon icon__svg">
                        <svg width="14" viewBox="0 0 18 20" fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                            <path d="M5.625 0V1.11111H0V3.33333H1.125V17.7778C1.125 18.3671 1.36205 18.9324 1.78401 19.3491C2.20597 19.7659 2.77826 20 3.375 20H14.625C15.2217 20 15.794 19.7659 16.216 19.3491C16.6379 18.9324 16.875 18.3671 16.875 17.7778V3.33333H18V1.11111H12.375V0H5.625ZM3.375 3.33333H14.625V17.7778H3.375V3.33333ZM5.625 5.55556V15.5556H7.875V5.55556H5.625ZM10.125 5.55556V15.5556H12.375V5.55556H10.125Z"
                                  fill="currentColor"/>
                        </svg>
                    </i>
                </button>
            </div>
            <ng-container *ngIf="isLoading">
                <mat-spinner class="of-loading" diameter="40"></mat-spinner>
            </ng-container>

            <input name="{{column?.propertyName}}"
                   type="hidden"/>

            <div class="of-upload__zone" *ngIf="!base64 && !isLoading">
                <div (fileOver)="fileOverBase($event)"
                     [ngClass]="{'nv-file-over': hasBaseDropZoneOver}"
                     [uploader]="getUploaderConfiguration(column)"
                     class="well upload-drop-zone"
                     ng2FileDrop>

                    <input (onFileSelected)="onDropResource($event, column)"
                           [uploader]="getUploaderConfiguration(column)"
                           ng2FileSelect
                           type="file"
                           [accept]="column.uploadConfig.accept"
                           />

                    <div class="of-upload__content">
                        <i class="icon icon__svg icon__upload">
                            <svg width="36" height="37" viewBox="0 0 36 37" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path opacity="0.4"
                                      d="M12 18.9534V5.2096C12 3.31982 13.5429 1.77364 15.4286 1.77364H30.8571C32.7429 1.77364 34.2857 3.31982 34.2857 5.2096V15.5175H28.4057L26.2114 12.5282C26.1086 12.3736 25.9029 12.3736 25.8 12.5282L22.3886 17.2355C22.2857 17.3385 22.08 17.3557 21.9771 17.2355L19.5257 14.229C19.4229 14.1087 19.2343 14.1087 19.1314 14.229L15.5143 18.7988C15.3771 18.9534 15.4971 19.2111 15.7029 19.2111H26.5714V22.3894H15.4286C13.5257 22.3894 12 20.8604 12 18.9534ZM6.85714 34.4152V32.6973H3.42857V34.4152H0V0.0556641H3.42857V1.77364H6.85714V0.0556641H10.9543C9.49714 1.32697 8.57143 3.14803 8.57143 5.2096V18.9534C8.57143 22.7502 11.64 25.8253 15.4286 25.8253H23.4857C21.72 27.2513 20.5714 29.3987 20.5714 31.8383C20.5714 32.7488 20.76 33.6078 21.0514 34.4152H6.85714ZM3.42857 8.64556H6.85714V5.2096H3.42857V8.64556ZM3.42857 15.5175H6.85714V12.0815H3.42857V15.5175ZM3.42857 22.3894H6.85714V18.9534H3.42857V22.3894ZM6.85714 29.2613V25.8253H3.42857V29.2613H6.85714ZM36 18.9534V22.3894H32.5714V31.8383C32.5714 34.2091 30.6514 36.1332 28.2857 36.1332C25.92 36.1332 24 34.2091 24 31.8383C24 29.4675 25.92 27.5433 28.2857 27.5433C28.9029 27.5433 29.4686 27.6636 30 27.9041V18.9534H36Z"
                                      fill="currentColor"/>
                            </svg>
                        </i>

                        <div class="of-field__upload-label">
                            <span>{{getTranslateLabel(uploadLabelKey) | async}}</span>
                        </div>

                        <span class="of-upload__button">{{'general.upload_button' | translate}}</span>
                    </div>
                </div>
            </div>

            <div class="of-field__outline" *ngIf="!canPreviewResource(column)">
                <div class="of-field__outline-o1"></div>
                <div class="of-field__outline-o2">
                    <label class="of-field__outline-label">
                        {{column.translateKey}}
                    </label>
                </div>
                <div class="of-field__outline-o3"></div>
            </div>
        </div>

        <mat-error style="margin-top: 10px;" *ngIf="error">{{error}}</mat-error>
    </div>
</ng-container>
