<webcam [height]="500" [width]="500" [trigger]="triggerObservable"
        (imageCapture)="handleImage($event)" *ngIf="showWebcam"
        [allowCameraSwitch]="allowCameraSwitch" [switchCamera]="nextWebcamObservable"
        [videoOptions]="videoOptions"
        [imageQuality]="imageQuality"
        (cameraSwitched)="cameraWasSwitched($event)"
        (initError)="handleInitError($event)"

></webcam>
<!--<img [src]="webcamImage?.imageAsDataUrl"/>-->
<!--{{imageQuality}}-->

<!--<br/>-->
<!--<button class="actionBtn" (click)="triggerSnapshot();">Take A Snapshot</button>-->
<button class="actionBtn of-button button--stroked"
        mat-stroked-button
        (click)="toggleWebcam();">{{ 'app_execution.toggleWebcam' | translate}}</button>
<!--<br/>-->
<!--<button class="actionBtn" (click)="showNextWebcam(true);" [disabled]="!multipleWebcamsAvailable">Next Webcam</button>-->
<!--<input id="cameraSwitchCheckbox" type="checkbox" [(ngModel)]="allowCameraSwitch"><label for="cameraSwitchCheckbox">Allow-->
<!--    Camera Switch</label>-->
<!--<br/>-->
<!--<button (click)="showNextWebcam(deviceId);">Activate</button>-->
