<div *ngIf="isLoading" class="of-loading">
    <mat-spinner diameter="60"></mat-spinner>
</div>

<section *ngIf="!isLoading">

    <ng-container *ngIf="missionAssignmentsToDisplay.length > 0">
        <div class="assignment-section">
            <h2 class="explore-header">Explore other entries</h2>

            <div class="of-mission__steps">
                <ng-container *ngFor="let step of missionAssignmentsToDisplay">
                    <of-mission-step
                            [assignments]="assignments"
                            [missionStep]="step"></of-mission-step>
                </ng-container>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="missions.length > 0">
        <h2 class="explore-header">Explore other missions</h2>

        <div class="list-grid">
            <div *ngFor="let mission of missions" class="list-grid__item">
                <of-list-item [missionEngagements]="missionEngagements" [item]="mission"></of-list-item>
            </div>
        </div>
    </ng-container>

</section>
