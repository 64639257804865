<div class="of-input-container of-input-container--audio">
    <div class="of-audio">
        <div class="of-audio__result" *ngIf="url">
            <audio #audioSource controls="">
                <source [src]="sanitize(url)" type="audio/wav">
            </audio>
        </div>

        <div class="of-audio--record">
            <div class="of-audio__wrapper" [class.is-playing]="showWave || url">
                <div class="of-audio__buttons">
                    <div *ngIf="!recording && !url"
                         class="button button__icon button--recording-start"
                         (click)="initiateRecording()"
                         [matTooltipPosition]="'before'"
                         [matTooltip]="'microphone.record' | translate">
                        <svg width="32" height="32" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M24 48C37.2548 48 48 37.2548 48 24C48 10.7452 37.2548 0 24 0C10.7452 0 0 10.7452 0 24C0 37.2548 10.7452 48 24 48Z" fill="white"/>
                            <path d="M27.112 34.889H20.8876C19.1755 34.889 17.7753 36.2892 17.7753 38.0013H30.2167C30.2243 36.2892 28.8242 34.889 27.112 34.889ZM31.2592 24.5174C31.2592 28.52 28.0023 31.7768 23.9998 31.7768C19.9973 31.7768 16.7404 28.52 16.7404 24.5174H14.6631C14.6631 29.6614 18.8482 33.8542 23.9998 33.8542C29.1437 33.8542 33.3365 29.669 33.3365 24.5174H31.2592ZM20.8876 24.5174H18.8102C18.8102 27.371 21.1463 29.6994 23.9922 29.6994C26.8457 29.6994 29.1818 27.3634 29.1818 24.5174H27.1044C26.5337 24.5174 26.0696 24.0533 26.0696 23.4826C26.0696 22.9119 26.5337 22.4477 27.1044 22.4477H29.1818V20.3703H27.1044C26.5337 20.3703 26.0696 19.9062 26.0696 19.3355C26.0696 18.7647 26.5337 18.3006 27.1044 18.3006H29.1818V16.2232H27.1044C26.5337 16.2232 26.0696 15.759 26.0696 15.1883C26.0696 14.6176 26.5337 14.1535 27.1044 14.1535H29.0753C28.5883 12.0761 26.4881 10.0063 23.9922 10.0063C21.4963 10.0063 19.3961 12.0761 18.9091 14.1535H20.88C21.4507 14.1535 21.9148 14.6176 21.9148 15.1883C21.9148 15.759 21.4507 16.2232 20.88 16.2232H18.8026V18.3006H20.88C21.4507 18.3006 21.9148 18.7647 21.9148 19.3355C21.9148 19.9062 21.4507 20.3703 20.88 20.3703H18.8026V22.4477H20.88C21.4507 22.4477 21.9148 22.9119 21.9148 23.4826C21.9224 24.0533 21.4583 24.5174 20.8876 24.5174Z" fill="#E04444"/>
                        </svg>
                    </div>
                    <div *ngIf="recording"
                         class="button button__icon button--recording-save"
                         (click)="stopRecording()"
                         [matTooltipPosition]="'before'"
                         [matTooltip]="'microphone.record' | translate">
                        <svg width="32" height="32" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M15 0C6.72757 0 0 6.72757 0 15C0 23.2724 6.72757 30 15 30C23.2724 30 30 23.2724 30 15C30 6.72757 23.2724 1e-06 15 0ZM11.041 9H18.959C20.0728 9 21 9.92722 21 11.041V18.959C21 20.0728 20.0728 21 18.959 21H11.041C9.92723 21 9 20.0728 9 18.959V11.041C9 9.92723 9.92723 9 11.041 9Z"
                                  fill="white"/>
                            <rect x="9" y="9" width="12" height="12" rx="2" fill="#E04444"/>
                        </svg>
                    </div>
                    <div *ngIf="url && !isPlaying" (click)="play()">
                        <svg width="32" height="32" viewBox="0 0 90 90" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M45 90C69.8528 90 90 69.8528 90 45C90 20.1472 69.8528 0 45 0C20.1472 0 0 20.1472 0 45C0 69.8528 20.1472 90 45 90Z" fill="white"/>
                            <path d="M68.5998 42.4L34.2998 22.6C32.2998 21.4 29.7998 22.9 29.7998 25.2V64.8C29.7998 67.1 32.2998 68.6 34.2998 67.4L68.5998 47.6C70.5998 46.4 70.5998 43.6 68.5998 42.4Z" fill="#E04444"/>
                        </svg>
                    </div>

                    <div *ngIf="url && isPlaying"
                         class="button button__icon button--recording-save"
                         (click)="pause()"
                         [matTooltipPosition]="'before'"
                         [matTooltip]="'microphone.record' | translate">
                        <svg width="32" height="32" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M15 0C6.72757 0 0 6.72757 0 15C0 23.2724 6.72757 30 15 30C23.2724 30 30 23.2724 30 15C30 6.72757 23.2724 1e-06 15 0ZM11.041 9H18.959C20.0728 9 21 9.92722 21 11.041V18.959C21 20.0728 20.0728 21 18.959 21H11.041C9.92723 21 9 20.0728 9 18.959V11.041C9 9.92723 9.92723 9 11.041 9Z"
                                  fill="white"/>
                            <rect x="9" y="9" width="12" height="12" rx="2" fill="#E04444"/>
                        </svg>
                    </div>
                </div>

                <div #audioWave class="of-audio__wave">
                    <svg #svgEl [style.transform]="'translateX(-' + translateValue + 'px)'"  xmlns="http://www.w3.org/2000/svg"></svg>
                </div>

                <button *ngIf="url" class="of-button of-button__icon of-audio--remove" (click)="remove()">
                    <mat-icon color="warn">delete</mat-icon>
                </button>
            </div>
        </div>
    </div>
</div>
