<!-- [ngStyle]="{'background-image':' url(' + item?.image?.url + ')'}"  -->


<div class="item">
    <div class="like_button" >
        <mat-icon (click)="likePrompt(item)" class="mat" 
            [ngClass]="{'mat_red': item.likes.length > 0, 'cant-click': isLoadingLike }">
            favorite
        </mat-icon>
    </div>
    <div (click)="itemClicked()" [id]="'gallery' + item.id" 
    class="item-cover" 
    >
        <div class="dark-bg"></div>
        <img [src]="imageToLoad.src">
        <div class="item-footer">
            <div class="stats">
                <div class="likes">
                    <mat-icon class="mat" [ngClass]="{'mat_red': item.likes.length > 0}">favorite</mat-icon>
                    <span>{{item?.likesCount | humanNumber}}</span>
                </div>
                <div class="views">
                    <mat-icon class="mat">visibility</mat-icon>
                    <span>{{item?.viewsCount | humanNumber}}</span>
                </div>
            </div>
        </div>
    </div>
</div>