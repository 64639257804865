import { AfterViewInit, Component, EventEmitter, Inject, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { MoreInfoEventArg, MoreInfoEventType } from '../../service/events/more-info.service';
import { ServiceBusEvent } from '../../service/events/service-bus.event';

@Component({
  selector: 'of-more-info-panel',
  templateUrl: './more-info-panel.component.html',
  styleUrls: ['./more-info-panel.component.scss']
})
export class MoreInfoPanelComponent implements OnInit, AfterViewInit {

  @Input() open = false;

  @Output() close = new EventEmitter();

  template: TemplateRef<any>;

  canclose = false;

  constructor(
    private serviceBusEvent: ServiceBusEvent
  ) { }

  ngOnInit(): void {
  }
  
  ngAfterViewInit(): void {
    this.listenForMoreInfo();
    this.listenForClose();
  }

  closePanel(): void {

    if (!this.canclose) { return; }

    this.close.emit();
    this.serviceBusEvent.next(new MoreInfoEventArg(MoreInfoEventType.CLOSE, 'VIEW_TEMPLATE', this.template));
    this.template = null;
    this.open = false;
    this.canclose = false;
  }

  listenForMoreInfo(): void {
    this.serviceBusEvent.subscribe({
      next: (e: MoreInfoEventArg) => {
        if (e.getSubType() === MoreInfoEventType.VIEW) {
          this.template = e.template
          this.open = true;
          this.setcanclose();
        }
      },
      error: (error) => {
        console.log(error);
      }
    });
  }

  listenForClose(): void {
    this.serviceBusEvent.subscribe({
      next: (e: MoreInfoEventArg) => {
        if (e.getSubType() === MoreInfoEventType.OUTSIDECLOSE) {
          this.closePanel();
        }
      },
      error: (error) => {
        console.log(error);
      }
    });
  }

  setcanclose():void {
    setTimeout(() => {
      this.canclose = true;
    }, 1000);
  }
}
