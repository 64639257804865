import { NotificationService } from './../../service/notification.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'of-sign-in-or-register',
  templateUrl: './sign-in-or-register.component.html',
  styleUrls: ['./sign-in-or-register.component.scss']
})
export class SignInOrRegisterComponent implements OnInit {

  constructor(
    private notificationService: NotificationService
    ) { }

  ngOnInit(): void {
  }

  public showRegisterModal() {
    this.notificationService.showRegister();
  }

}
