import { DialogComponent } from './../../../core/components/dialog/dialog-rating/dialog.component';
import { PaginateCriteria } from './../../../core/service/crud/paginate.service';
import { MainPageComponent } from './../../../core/components/main-page/main-page.component';
import { TrackService } from './../../../core/service/crud/impl/track.service';
import { NotificationService } from './../../../core/service/notification.service';
import { UserService } from './../../../core/service/crud/impl/user.service';
import { LocalStorageService, LocalStorageConstants } from './../../../core/service/local-storage.service';
import { ListComponentService, ListEventArg } from './../../../core/service/events/list-component.service';
import { ContextualTranslationService } from './../../../core/service/translation/contextual-translation.service';
import { ServiceBusEvent } from './../../../core/service/events/service-bus.event';
import { PermissionService } from './../../../core/service/permission.service';
import { KeyValue } from './../../../core/model/ontology/model';
import { SearchCriteria } from './../../../core/service/crud/search.service';
import { ActionConfig } from './../../../core/service/model.service';
import { Gallery, GalleryStatus } from './../../../core/model/gallery';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { GalleryService } from './../../../core/service/crud/impl/gallery.service';
import { DetailComponentService } from './../../../core/service/events/detail-component.service';
import { ListComponent } from './../../../core/components/list/list.component';

@Component({
  selector: 'of-validate-prompt-page',
  templateUrl: './validate-prompt-page.component.html',
  styleUrls: ['./validate-prompt-page.component.scss'],
  providers: [
    GalleryService,
    ListComponentService,
    DetailComponentService,
    TrackService,
  ]
})
export class ValidatePromptPageComponent implements OnInit {

  @ViewChild('mainPage')
  public mainPage: MainPageComponent;

  @ViewChild('dialog')
  public dialog: DialogComponent;

  @ViewChild('settingsDialog')
  public settingsDialog: DialogComponent;
  
  public prototype: Gallery;

  public isLoading = false;
  public filterStatus = 'PENDING';

  public statuses = [
      GalleryStatus.APPROVED,
      GalleryStatus.PENDING,
      GalleryStatus.DENIED,
  ];

  public action: ActionConfig;
  
  public searchContributor: (criteria: SearchCriteria) => void;

  public index = 0;
  public valuation: KeyValue<any>;
  public gallery: Gallery;

  private settingsType = '';
  public messageTemplateForm: FormGroup;

  private emailTemplate = {
    completed: {
        title: `Prompt validated successfully`,
        message: `🥳🥳🥳 Congratulations! your prompt was validated. Your prompt will be displayed in the gallery hall of fame`,
        button: 'Upload more now'
    },

    failed: {
        title: `Prompt validation failed`,
        message: `💔💔💔 Oops! your submission is invalid. You are super close to placing your prompt in the gallery hall of fame`,
        button: 'Fix it Now'
    }
  };

  public notificationMessages: {
      completed: { title: string, message: string, button: string },
      failed: { title: string, message: string, button: string }
  } = null;

  private sub: any[] = [];

  public loadingGallery = false;

  constructor(
    public service: GalleryService,
    public permission: PermissionService,
    public serviceBusEvent: ServiceBusEvent,
    public translationService: ContextualTranslationService<Gallery>,
    private listService: ListComponentService<Gallery>,
    private localStorageService: LocalStorageService,
    private userService: UserService,
    private notificationService: NotificationService,
    private trackService: TrackService
    ) {
      this.prototype = Gallery.prototype;

      this.searchContributor = (criteria: SearchCriteria) => {
          criteria.clearFilters();
          criteria.include = 'author,image';
          // criteria.fields = { galleryImage: 'authorId,prompt,imageId,status' };
          criteria.addInFilter('galleryImage.status', [this.filterStatus]);
      };
     }

  ngOnInit(): void {
    this.action = {
      icon: 'build',
      description: 'Validate',
      actionEvent: 'validate',
    };

    this.initEvents();
  }

  private initEvents() {
    this.sub.push(
        this.serviceBusEvent.subscribe(async (event: ListEventArg) => {
            if (event.getSubType() === 'validate') {
                const selection = event.jsonApiModel as Gallery;
                const list = this.mainPage?.list as ListComponent;
                const criteria: PaginateCriteria = list?.criteria;
                const page = criteria?.page;
                this.index = (page?.number - 1) * page?.size + list.elements.indexOf(selection);
                this.dialog?.open();
                await this.navigateAssignment(0);
            }
        })
    );

    this.sub.push(
        this.listService.onReceiveResults.subscribe(async pageElements => {
            const list = this.mainPage?.list as ListComponent;
            if (this.index >= list.length) {
                // await this.navigateAssignment(-1);
            }
        })
    );
  }

  private async navigateAssignment(increment: number) {
    this.loadingGallery = true;
    
    let list = this.mainPage?.list as ListComponent;
    const criteria = list?.criteria;
    const pageSize = criteria?.page?.size;

    await (<ListComponent>this.mainPage?.list)?.pageAsync({
      length: list?.elements.length,
      pageIndex: Math.floor(this.index / pageSize),
      pageSize: pageSize
    });

    let next = this.index + increment;
    
    list = this.mainPage?.list as ListComponent;

    if (next >= list.length) {
        next = 0
    }

    if (next < 0) {
        next = list.length - 1
    }

    if (list.length == 0) {
      this.dialog.close();
    }

    this.index = next;

    const elements = list?.elements;

    const selection = elements[(this.index) % pageSize] as Gallery;

    if (selection) {
        this.gallery = selection;
        this.notificationMessages = this.getNotificationMessages(this.gallery);
    }
    
    this.loadingGallery = false;
  }


  public getNotificationMessages(gallery: Gallery) {

    const failed = this.getOrDefault(LocalStorageConstants.EMAIL_SETTINGS_FAILED_PROMPT, this.emailTemplate.failed);
    const completed = this.getOrDefault(LocalStorageConstants.EMAIL_SETTINGS_COMPLED_PROMPT, this.emailTemplate.completed);


    return {
      failed: {
        title: failed.title,
        message: failed.message,
        button: failed.button,
    },
    completed: {
        title: completed.title,
        message: completed.message,
        button: completed.button,
    }
    }
  }

  private getOrDefault(key: string, defaultValue: any) {
    if (!this.localStorageService.get(key)) {
        return defaultValue;
    }
    return this.localStorageService.get(key);
  }

  public filterByStatus(event) {
    this.mainPage.list.search();
  }

  public async goForward() {
    await this.navigateAssignment(1);
}

  public async goBack() {
      await this.navigateAssignment(-1);
  }

  public async markAsFailed() {
      this.gallery.status = GalleryStatus.DENIED
      await this.update();
      const increment = this.nextIncrement();
      await this.navigateAssignment(increment);
  }

  public async markAsCompleted() {
    this.gallery.status = GalleryStatus.APPROVED
      await this.update();
      const increment = this.nextIncrement();
      await this.navigateAssignment(increment);
  }

  private nextIncrement() {
      const list = this.mainPage?.list as ListComponent;

      if (this.index >= list.length - 1) {
        return ((this.index + 1) < list.length) ? 1 : -1;
      }

      return 0;

  }

  public closeModal() {
      this.mainPage?.list?.reload();
      this.isLoading = false;
      this.dialog?.close();
  }

  private async update() {
        
    this.loadingGallery = true;
    try {
        await this.service.updateRecord(this.gallery).toPromise();
        await this.notifyUserOfAction();
        
        this.loadingGallery = false;
        
    } catch (e) {
        console.log('Update assignment error: ', e);
        this.notificationService.notify('Failed to update user assignment');
        this.loadingGallery = false;
        
    } finally {
        this.loadingGallery = false;
    }
    
  }

  private async notifyUserOfAction(): Promise<void> {
    try {
        const email = this.gallery?.author?.email;
        if (this.gallery.status === GalleryStatus.APPROVED) {
            await this.userService.notify(email,
                this.notificationMessages.completed.title,
                this.notificationMessages.completed.message,
                this.notificationMessages.completed.button,
                '/gallery/'+ this.gallery.id
            );
        }

        if (this.gallery.status === GalleryStatus.DENIED) {
            await this.userService.notify(email,
                this.notificationMessages.failed.title,
                this.notificationMessages.failed.message,
                this.notificationMessages.failed.button,
                `/gallery`
            );
        }
    } catch (error) {
        console.log('Update assignment error: ', error);
        this.notificationService.notify('Failed to send email to user');
    }
  }

  public isValidInputOfMessageTemplateForm(inputName: string) {
    return this.messageTemplateForm.get(inputName).touched && this.messageTemplateForm.get(inputName).invalid;
  }

  public getErrorsOfInputOfMessageTemplateForm(inputName: string) {
      const keys = [];
      // tslint:disable-next-line:forin
      for (const key in this.messageTemplateForm.get(inputName).errors) {
          keys.push(key);
      }
      return keys;
  }

  public onChangeMessageTemplate() {
    if (this.messageTemplateForm.invalid) {
        return;
    }

    const {titleText, messageText, buttonText} = this.messageTemplateForm.value;
    const payload = {title: titleText, message: messageText, button: buttonText};
    this.localStorageService.set(this.settingsType, payload);
    this.settingsDialog?.close();
  }

  public setupCompletedSettings() {
    this.settingsType = LocalStorageConstants.EMAIL_SETTINGS_COMPLED_PROMPT;
    const completed = this.getOrDefault(this.settingsType, this.emailTemplate.completed);
    this.initEmailForm(completed);
    this.settingsDialog?.open();
  }

  public setupFailedSettings() {
      this.settingsType = LocalStorageConstants.EMAIL_SETTINGS_FAILED_PROMPT;
      const failed = this.getOrDefault(this.settingsType, this.emailTemplate.failed);
      this.initEmailForm(failed);
      this.settingsDialog?.open();
  }

  private initEmailForm({title, message, button}) {
    this.messageTemplateForm = new FormGroup({
        'messageText': new FormControl(message,
            [Validators.required],
        ),
        'titleText': new FormControl(title,
            [Validators.required]
        ),
        'buttonText': new FormControl(button, [Validators.required]),
    });
  }
}
