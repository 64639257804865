
<div class="of-dialog__header">
    <h4 class="of-dialog__header-title">Gallery details</h4>
    <button (click)="closeModal()"
            style="z-index: 2;"
            type="button"
            class="of-button of-button__close-modal">
        <i class="icon icon__svg">
            <svg width="12" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9.66857 8L16 14.3314V16H14.3314L8 9.66857L1.66857 16H0V14.3314L6.33143 8L0 1.66857V0H1.66857L8 6.33143L14.3314 0H16V1.66857L9.66857 8Z"
                      fill="currentColor"/>
            </svg>
        </i>
    </button>
</div>

<section *ngIf="isLoading">
    <mat-spinner style="margin: auto;" [strokeWidth]="5" [diameter]="100"></mat-spinner>
</section>

<section *ngIf="!isLoading && galleryToView">

    <button (click)="prev()" type="button" class="swiper__button swiper__button-prev">
        <i style="font-size: 40px;" class="ri-arrow-left-s-line"></i>
    </button>
    <button (click)="next()" type="button" class="swiper__button swiper__button-next">
        <i style="font-size: 40px;" class="ri-arrow-right-s-line"></i>
    </button>

    <div class="image-content">
        <div class="image-section">
            <img [src]="galleryToView?.image?.url" [alt]="galleryToView?.image?.id" />
        </div>
    </div>
    <div class="info-content">

        <div class="author">
            <strong class="creator">Created {{ galleryToView?.createdAt | humanTime }} by</strong>
            <div class="user-name">
                <div class="profile" (click)="userClicked()">
                    <mat-icon *ngIf="!galleryToView?.author?.image" class="mat">account_circle</mat-icon>
                    <img *ngIf="galleryToView?.author?.image" [src]="galleryToView?.author?.image" [alt]="galleryToView?.author?.image">
                </div>
                <span (click)="userClicked()">{{galleryToView?.author?.displayName}}</span>
            </div>
        </div>

        <div class="prompt">
            <small>
                {{ galleryToView?.prompt }}
            </small>
        </div>


        <div class="status-cover">
            
            <button (click)="copyToClipboard()" mat-mini-fab color="primary" class="copy-btn" aria-label="Example icon button with a menu icon">
                <mat-icon class="mat">copy_all</mat-icon>
            </button>

            <div class="status">
                <div (click)="likePrompt(galleryToView)" class="btn-content">
                    <mat-icon class="mat" [ngClass]="{'mat_red': galleryToView?.likes?.length > 0, 'cant-click': isLoadingLike }">favorite</mat-icon>
                    <span>{{galleryToView?.likesCount | humanNumber}}</span>
                </div>
                <div class="btn-content">
                    <mat-icon class="mat">visibility</mat-icon>
                    <span>{{galleryToView?.viewsCount | humanNumber}}</span>
                </div>
            </div>
        </div>
    
        <div class="detail-section">

            <div class="prompt-area">
                <div class="prompt-actions">
                    <div>
                        <a href="https://discord.com/channels/1080095228171665418/1113465781095759954" target="_blank" rel="noopener noreferrer">
                            <button mat-raised-button color="primary" class="btn">
                                <div class="btn-content">
                                    <mat-icon class="mat">add_circle_outline</mat-icon>
                                    <span>{{'gallery.submit_prompt' | translate}}</span>
                                </div>
                            </button>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
