import { AuthService } from './../../../core/service/auth.service';
import { Gallery, GalleryStatus } from './../../../core/model/gallery';
import { GalleryService } from './../../../core/service/crud/impl/gallery.service';
import { DialogComponent } from './../../../core/components/dialog/dialog-rating/dialog.component';
import { DetailComponentService } from './../../../core/service/events/detail-component.service';
import { FilterOperator } from './../../../core/service/crud/search.service';
import { ContextualTranslationService } from './../../../core/service/translation/contextual-translation.service';
import { PermissionService } from './../../../core/service/permission.service';
import { PaginateCriteria } from './../../../core/service/crud/paginate.service';
import { ActionConfig } from './../../../core/service/model.service';
import { UserRewardStat } from './../../../core/model/user-reward-stat';
import { AfterContentChecked, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { UserRewardStatService } from './../../../core/service/crud/impl/user-reward-stat.service';
import { ListComponentService } from './../../../core/service/events/list-component.service';

@Component({
  selector: 'of-gallery-page',
  templateUrl: './gallery-page.component.html',
  styleUrls: ['./gallery-page.component.scss'],
  providers: [
    UserRewardStatService,
    PermissionService,
    ListComponentService,
    DetailComponentService,
    ContextualTranslationService,
    GalleryService
  ]
})
export class GalleryPageComponent implements OnInit, AfterContentChecked {
  @ViewChild('mainPageComponent') 
  public mainPageComponent;

  @ViewChild('dialog')
  public dialog: DialogComponent;

  public selection: Gallery;
  public prototype: Gallery;
  public action: ActionConfig;

  public searchContributor: (criteria: PaginateCriteria) => void;

  private sub: any[] = [];

  public filterState = 'recent';
  
  constructor(
    public service: GalleryService,
    public permission: PermissionService,
    public translationService: ContextualTranslationService<Gallery>,
    protected detailComponentService: DetailComponentService<Gallery>,
    protected listComponentService: ListComponentService<Gallery>,
    private cdref: ChangeDetectorRef,
    private authService: AuthService
  ) {
    this.prototype = Gallery.prototype;

    this.searchContributor = (criteria: PaginateCriteria) => {

        criteria.clearFilters();

        criteria.include = 'author,image';

        // criteria.addInFilter('galleryImage.likes.userId', [this.authService.getUserId()])
        criteria.addInFilter('galleryImage.status', [GalleryStatus.APPROVED]);

        criteria.clearSort();
        if (this.filterState == 'recent') {
          criteria.addSort('createdAt', 'desc')
        } else {          
          criteria.addSort('likesCount', 'desc')
        }
        // criteria.filter['galleryImage.likes.userId'] = this.authService.getUserId();
    };
   }

  ngOnInit(): void {
  }

  ngAfterContentChecked() {
    this.cdref.detectChanges();
  }

  openModal(): void {
    this.dialog?.open();
  }

  closeModal(): void {
    this.dialog?.close();
  }

  public setTimeFilter(name: string): void {

    if (name === this.filterState) {
        return;
    }

    const oldName = this.filterState;

    this.filterState = name;
    this.mainPageComponent?.list?.restartSearch(name, oldName);
}
}
