import { GalleryLikeEventArg, GalleryLikeEventType } from './../../../../core/service/events/gallery-like.event';
import { DialogComponent } from './../../../../core/components/dialog/dialog-rating/dialog.component';
import { GalleryImageLikeState, GalleryLike } from './../../../../core/model/galleryLike';
import { AuthService } from './../../../../core/service/auth.service';
import { GalleryLikeService } from './../../../../core/service/crud/impl/galleryLike.service';
import { Gallery } from './../../../../core/model/gallery';
import { Subscription } from 'rxjs';
import { MoreInfoEventArg, MoreInfoEventType } from '../../../../core/service/events/more-info.service';
import { ServiceBusEvent } from '../../../../core/service/events/service-bus.event';
import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PaginateCriteria } from '../../../../core/service/crud/paginate.service';
import { FilterOperator } from '../../../../core/service/crud/search.service';

@Component({
  selector: 'of-gallery-list',
  templateUrl: './gallery-list.component.html',
  styleUrls: ['./gallery-list.component.scss'],
  providers: [
    GalleryLikeService
  ]
})
export class GalleryListComponent implements OnInit, OnChanges, OnDestroy {

  @Input() public gallery: Gallery[] = [];

  @ViewChild('template') template: TemplateRef<any>;
  @ViewChild('templateUser') templateUser: TemplateRef<any>;
  @ViewChild('dialog') public dialog: DialogComponent;

  chuckedItems = []

  selected = null;
  userId = null;
  subscriptions: Subscription[] = [];

  galleryToDisplay: Gallery[] = [];

  constructor(
    private serviceBusEvent: ServiceBusEvent,
    private gallerLikeService: GalleryLikeService,
    private authService: AuthService,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.listenForMoreInfo();
    this.listenForPromptLikeToggle();
    this.listenForViewPrompt();
    this.openUrl();
  }

  ngOnChanges(changes: SimpleChanges): void {
    // window.scrollBy(0, 50);
    this.galleryToDisplay = this.gallery;

    this.setIncomingGalleries();
  }

  itemSelected(item: any): void {
    this.selected = item;
    this.openModal();
    // this.serviceBusEvent.next(new MoreInfoEventArg(MoreInfoEventType.VIEW, 'VIEW_TEMPLATE', this.template));
  }

  userSelected(item: any): void {
    this.userId = item.authorId;
    this.selected = item.gallery;
    this.serviceBusEvent.next(new MoreInfoEventArg(MoreInfoEventType.VIEW, 'VIEW_TEMPLATE', this.templateUser));
  }

  closePanel(): void {
    const id = this.selected?.id;
    this.selected = null;
    this.userId = null;
    const element = document.getElementById('gallery' + id);
    if (element) {
      element.scrollIntoView();
    }
  }

  listenForMoreInfo(): void {
    const sub = this.serviceBusEvent.subscribe({
      next: (e: MoreInfoEventArg) => {
        if (e.getSubType() === MoreInfoEventType.CLOSE) {
          this.closePanel();
        }
      },
      error: (error) => {
        console.log(error);
      }
    });
    this.subscriptions.push(sub);
  }

  private openUrl(): void {
    const url = this.route.snapshot.paramMap.get('id');
    if (url && url.length > 5) {
      setTimeout(() => {
        this.itemSelected(null);
      }, 1000);
    }
  }

  openModal(): void {
    this.dialog?.open();
  }

  closeModal(): void {
    this.dialog?.close();
  }

  async setIncomingGalleries(pageSize: number = 50): Promise<void> {

    if (!this.authService.isLoggedIn()) {
      return;
    }

    const newElements = this.galleryToDisplay.slice(-pageSize);
    const mylikes = await this.gallerLikeService.getMyLikes(newElements.map(x =>x.id));

    mylikes.forEach(x => {
      const likesFound = this.galleryToDisplay.find(a => a.id.toString() === x.galleryImageId.toString());

      if (likesFound) {
        likesFound.likes = [x]
      }
    });
  }

  listenForPromptLikeToggle(): void {
    const sub = this.serviceBusEvent.subscribe({
      next: (e: GalleryLikeEventArg) => {
        if (e.getSubType() === GalleryLikeEventType.TOGGLETHUMSBS) {
          const gallery = this.gallery.find(x => x.id.toString() === e.identifier);

          if (gallery) {
            gallery.likes = e.galleryLike;
            gallery.likesCount = e.galleryLike.length > 0 ? gallery.likesCount + 1 : gallery.likesCount - 1;
          }
        }
      },
      error: (error) => {
        console.log(error);
      }
    });

    this.subscriptions.push(sub);
  }

  listenForViewPrompt(): void {
    const sub = this.serviceBusEvent.subscribe({
      next: (e: GalleryLikeEventArg) => {
        if (e.getSubType() === GalleryLikeEventType.PROMPTVIEWED) {
          const gallery = this.gallery.find(x => x.id.toString() === e.identifier);

          if (gallery) {
            gallery.viewsCount++;
          }
        }
      },
      error: (error) => {
        console.log(error);
      }
    });

    this.subscriptions.push(sub);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(x => x.unsubscribe());
  }
}
