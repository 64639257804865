import { GalleryLike } from "../../model/galleryLike";
import { EventArg, EventType } from "./service-bus.event";

export enum GalleryLikeEventType {
    TOGGLETHUMSBS = 'TOGGLETHUMSBS',
    TOGGLETHUMSBS_NONE = 'TOGGLETHUMSBS_NONE',
    PROMPTVIEWED = 'PROMPTVIEWED',
    PROMPTVIEWED_INTERNAL = 'PROMPTVIEWED_INTERNAL',
    THUMBS_UP_ADD = 'THUMBS_UP_ADD',
    THUMBS_UP_REMOVE = 'THUMBS_UP_REMOVE',
  }
  
  export class GalleryLikeEventArg extends EventArg {
    public galleryLikeEventType: GalleryLikeEventType;
    public galleryLike: GalleryLike[] = []
  
    constructor(galleryLikeEventType: GalleryLikeEventType, identifier: string, galleryLike: GalleryLike[] = []) {
        super(EventType.CUSTOM_ACTION, identifier);
        this.galleryLikeEventType = galleryLikeEventType;
        this.galleryLike = galleryLike;
    }
  
    getSubType(): any {
        return this.galleryLikeEventType;
    }
  }
  