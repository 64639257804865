import { Injectable, TemplateRef } from '@angular/core';
import { EventArg, EventType } from './service-bus.event';

export enum MoreInfoEventType {
  VIEW = 'VIEW',
  CLOSE = 'CLOSE',
  OUTSIDECLOSE = 'OUTSIDECLOSE',
}

export class MoreInfoEventArg extends EventArg {
  public moreInfoEventType: MoreInfoEventType;
  public template: TemplateRef<any>;

  constructor(moreInfoEventType: MoreInfoEventType, identifier: string, template: TemplateRef<any>) {
      super(EventType.CUSTOM_ACTION, identifier);
      this.moreInfoEventType = moreInfoEventType;
      this.template = template;
  }

  getSubType(): any {
      return this.moreInfoEventType;
  }
}

@Injectable({
  providedIn: 'root'
})
export class MoreInfoService {

  constructor() { }
}
