import {Injectable, OnDestroy} from '@angular/core';
// @ts-ignore
import {ethers} from 'ethers';
import {EthersProviderService} from './ethers-provider.service';
import {SessionService} from './session.service';

let ofEthereumProvider = null;

@Injectable({
    providedIn: 'root'
})
export class EthersService implements OnDestroy {
    constructor(protected readonly ethersProviderService: EthersProviderService,
                protected readonly sessionService: SessionService) {
    }

    public async initEventsListener() {
        const ethereum = await this.getEthereum();

        if (ethereum) {
            ethereum.on('chainChanged', this.handleChainChange);
        }
    }

    public async contract(address, abi) {
        const provider = await this.getProvider();

        if (!provider) {
            return null;
        }

        // @ts-ignore
        return new ethers.Contract(address, address, provider);
    }

    public amount(value) {
        return ethers.utils.parseEther(value);
    }

    public async disconnect() {
        // @ts-ignore
        const ethereum = await this.ethersProviderService.detectEthereumProvider();
        // @ts-ignore
        ethereum?.provider = null;
    }

    public async isInstalled() {
        try {
            // @ts-ignore
            const provider = await this.getEthereum();

            return !!provider;
        } catch (e) {
            this._handleError(e);
            return null;
        }
    }

    public async connect() {
        try {
            // @ts-ignore
            const ethereum = await this.getEthereum();

            if (!ethereum?.provider) {

                // @ts-ignore
                if (!ethereum || !ethereum?.isMetaMask) {
                    this._handleError('Can\'t detect Metamask');
                    return null;
                }

                await ethereum?.request({
                    method: 'wallet_requestPermissions',
                    params: [{eth_accounts: {}}]
                });
            }
        } catch (ex) {
            if (ex.code === -32002) {
                return null;
            }
            if (ex.code === 4001) {
                // remove wallet
            }
            this._handleError(ex);
        }

        return null;
    }

    public async getSigner() {
        try {
            const provider = await this.getProvider();
            if (!provider) {
                return null;
            }
            // @ts-ignore
            return provider.getSigner();
        } catch (e) {
            this._handleError(e);
            return null;
        }
    }

    public async getBalance() {
        let balance = this.sessionService.getFromSession('balance');
        if (!balance) {
            const connection = await this.checkConnection();

            try {
                if (!connection) {
                    await this.connect();
                }
                const provider = await this.getProvider();
                if (!provider) {
                    return;
                }

                const address = await this.getAddress();
                const value = await provider?.getBalance(address);

                if (value) {
                    balance = ethers.utils.formatEther(value);
                    const expiration = 60000 * 10;
                    this.sessionService.addToSession('balance', balance, expiration);
                }
            } catch (e) {
                this._handleError(e);
                return null;
            }
        }

        return this.sessionService.getFromSession('balance') as string;
    }

    public async getAddress() {
        try {
            await this.connect();
            const provider = await this.getProvider();
            return await provider?.send('eth_requestAccounts', []);
        } catch (e) {
            this._handleError(e);
            return null;
        }
    }

    public async signMessage(message: any) {
        try {
            const signer = await this.getSigner();
            return (signer) ? await signer.signMessage(message) : null;
        } catch (e) {
            this._handleError(e);
            return null;
        } finally {
            console.log('sign message');
        }
    }

    public async ngOnDestroy() {
        // const ethereum = await this.getEthereum();
        //
        // if (ethereum) {
        //     ethereum.off('chainChanged', this.handleChainChange);
        // }
    }

    public async getEthereum() {
        if (!ofEthereumProvider) {
            try {
                ofEthereumProvider = await this.ethersProviderService.detectEthereumProvider();
            } catch (e) {
                return null;
            }
        }

        return ofEthereumProvider;
    }

    public async getProvider() {
        try {

            const connection = await this.checkConnection();

            if (!connection) {
                await this.connect();
            }

            const ethereum = await this.getEthereum();

            if (!ethereum) {
                return null;
            }

            return new ethers.providers.Web3Provider(ethereum, 'any');
        } catch (e) {
            console.log('Error network check: ', e);
            return null;
        }
    }

    public async importNFT(tokenId, address) {
        const ethereum = await this.getEthereum();

        if (!ethereum) {
            return null;
        }

        try {
            return await ethereum.request({
                method: 'wallet_watchAsset',
                params: {
                    type: 'ERC721',
                    options: {
                        address: address,
                        tokenId: tokenId
                    },
                },
            });
        } catch (e) {
            console.error('Error importing NFT to metamask', e);
            if (e.code === -32002) {
                console.log('Error -32002: ', e?.message);
                throw new Error(e?.message);
            }

            throw new Error('Error importing NFT to metamask. Please check your metamask account!');
        }
    }

    private async getAccount() {
        const ethereum = await this.getEthereum();

        if (!ethereum) {
            return null;
        }

        const accounts = await ethereum.request({method: 'eth_accounts'});
        return accounts.length === 0 ? null : accounts[0];
    }

    private async checkConnection() {
        return !!await this.getAccount();
    }

    private handleChainChange = (id) => {

    };

    private isChainSelected(ethereum: any, targetChainId: string): boolean {
        return ethereum?.chainId === targetChainId;
    }

    private async requestAccounts() {
        const ethereum = await this.getEthereum();
        await ethereum.request({method: 'eth_requestAccounts'});
    }

    public async switchNetwork(config: { chainId: string, chainName: string, rpcUrls: string[] }): Promise<Object> {
        const ethereum = await this.getEthereum();

        try {
            // @ts-ignore
            await ethereum?.request({
                method: 'wallet_switchEthereumChain',
                params: [{chainId: config.chainId}],
            });
            return true;

        } catch (ex) {
            console.log('switch error: ', ex);
            // The network has not been added to MetaMask
            if (ex.code === 4902 || ex.code === -32603) {
                const installed = await this.installNetwork(config);
                if (installed) {
                    return this.switchNetwork(config);
                }
            } else {
                this._handleError(ex);
                return false;
            }
        }
    }

    private async installNetwork(config): Promise<boolean> {
        // @ts-ignore
        const ethereum = await this.getEthereum();
        const params = {
            chainId: config?.chainId,
            chainName: config?.chainName,
            nativeCurrency: config?.nativeCurrency,
            rpcUrls: [config?.rpcUrls]
        };

        if (!ethereum) {
            return;
        }
        try {
            await ethereum?.request({
                method: 'wallet_addEthereumChain',
                params: [params]
            });
            return true;
        } catch (ex) {
            this._handleError(ex);
            return false;
        }
    }

    private _handleError(ex): void {
        console.error(ex);
    }
}
