import { GalleryLikeEventArg, GalleryLikeEventType } from './../../../core/service/events/gallery-like.event';
import { AuthService } from './../../../core/service/auth.service';
import { FilterOperator } from './../../../core/service/crud/search.service';
import { PaginateCriteria } from './../../../core/service/crud/paginate.service';
import { GalleryService } from './../../../core/service/crud/impl/gallery.service';
import { MoreInfoEventArg, MoreInfoEventType } from './../../../core/service/events/more-info.service';
import { ServiceBusEvent } from './../../../core/service/events/service-bus.event';
import { NotificationService } from './../../../core/service/notification.service';
import { Gallery } from './../../../core/model/gallery';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import {Clipboard} from '@angular/cdk/clipboard';
import { Router } from '@angular/router';
import { GalleryViewService } from './../../../core/service/crud/impl/galleryView.service';
import { GalleryLikeService } from './../../../core/service/crud/impl/galleryLike.service';
import { Subscription } from 'rxjs';


@Component({
  selector: 'of-single-gallery-page',
  templateUrl: './single-gallery-page.component.html',
  styleUrls: ['./single-gallery-page.component.scss'],
  providers: [
    NotificationService,
    GalleryService,
    GalleryViewService,
    GalleryLikeService
  ]
})
export class SingleGalleryPageComponent implements OnInit, OnDestroy {

  @Input() galleryList: Gallery[] = [];
  @Input() gallery: Gallery;

  
  @Output() close = new EventEmitter();
  @Output() userSelected = new EventEmitter();

  url = '';
  isLoading = false;
  index = 0;
  isLoadingLike = false;
  isLoadingView = false;
  subscriptions: Subscription[] = [];
  galleryToUse: Gallery[] = [];
  galleryToView: Gallery;

  constructor(
    private clipboard: Clipboard,
    private notificationService: NotificationService,
    private router: Router,
    private serviceBusEvent: ServiceBusEvent,
    private galleryService: GalleryService,
    private galleryViewService: GalleryViewService,
    private gallerLikeService: GalleryLikeService,
    private authService: AuthService
  ) { }
  
  async ngOnInit(): Promise<void> {
    const d = this.galleryList
    this.galleryToUse = [...d];
    // this.setUrl();
    this.listenForViewPrompt();
    await this.initializeGallery();
    this.setIndex();
  }

  copyToClipboard(): void {
    this.clipboard.copy(this.galleryToView.prompt);
    this.notificationService.notify('Prompt copied');
  }

  setUrl(): void {
    this.url = window.location.origin + '/gallery/' + this.galleryToView.id;
  }

  async initializeGallery(): Promise<void> {

    let galleryId = null;
    this.isLoading = true;

    if (this.gallery) { galleryId = this.gallery.id }
    else {
      const galleryIdFromUrl = window.location.origin + '/gallery/';
  
      galleryId = window.location.href.slice(galleryIdFromUrl.length);
    }


    if (!galleryId) {
      this.notifyUserAndClose();
      return;
    }

    const criteria = new PaginateCriteria(1, 1);
    criteria.include = 'author,image';
    criteria.addFilter('galleryImage.id', FilterOperator.EQUALS, galleryId);

    const data = await this.galleryService.findAll(criteria).toPromise();

    if (data.getMeta().meta.page.totalRecords == 0) {
      this.notifyUserAndClose();
      return;
    }

    this.galleryToView = data.getModels()[0];
    this.setUrl();

    this.isLoading = false;
    await this.updateView(this.galleryToView.id);
    await this.setInitialGalleryLike();
  }

  notifyUserAndClose(): void {
    this.notificationService.notify('Gallery was not found');
    setTimeout(() => {
      this.serviceBusEvent.next(new MoreInfoEventArg(MoreInfoEventType.OUTSIDECLOSE, 'VIEW_TEMPLATE', {} as any));
    }, 200);
  }

  async updateView(galleryId: string, internal = true): Promise<void> {

    if (!this.authService.isLoggedIn()) {
      return;
    }

    this.isLoadingView = true;
    try {
      const viewd = await this.galleryViewService.haveViewed(this.authService.getUserId(), galleryId);
      
      if (!viewd) {
        const d = await this.galleryViewService.createRecord({ userId: this.authService.getUserId(), galleryImageId: galleryId }).toPromise();
        
        if (internal) {
          this.serviceBusEvent.next(new GalleryLikeEventArg(GalleryLikeEventType.PROMPTVIEWED_INTERNAL, galleryId, []));
        }
        this.serviceBusEvent.next(new GalleryLikeEventArg(GalleryLikeEventType.PROMPTVIEWED, galleryId, []));
      }

    } catch (error) {
      
    } finally {
      this.isLoadingView = false;
    }
  }

  closeModal(): void {
    this.close.emit();
  }

  setIndex(): void {
    let index = this.galleryToUse.map(x => x.id).indexOf(this.galleryToView.id);

    if (index < 0) {
      this.galleryToUse.push(this.galleryToView);
      index = this.galleryToUse.map(x => x.id).indexOf(this.galleryToView.id);
    }

    this.index = index;
  }

  async next(): Promise<void> {
    const gallery = this.galleryToUse[this.index + 1];

    if (gallery) {
      this.galleryToView = gallery;
      this.index++;
    } else {
      this.index = 0
      this.galleryToView = this.galleryToUse[this.index];
    }
    await this.updateView(this.galleryToView.id, false)
  }

  async prev(): Promise<void> {
    const gallery = this.galleryToUse[this.index - 1];
    if (gallery) {
      this.galleryToView = gallery;
      this.index--;
    } else {
      this.index = this.galleryToUse.length - 1
      this.galleryToView = this.galleryToUse[this.index];
    }
    await this.updateView(this.galleryToView.id, false)
  }

  userClicked(): void {
    this.closeModal();
    this.userSelected.emit({authorId: this.galleryToView.author.id, gallery: this.galleryToView});
  }

  async likePrompt(item: Gallery): Promise<void> {

    if (!this.authService.isLoggedIn()) {
      this.notificationService.showRegister();
      return;
    }

    if (this.isLoadingLike === true) { return; }

    this.isLoadingLike = true;
    const result = await this.gallerLikeService.togglePrompt(item, item.likes[0]);
    
    if (result.result != 0) {
      this.serviceBusEvent.next(new GalleryLikeEventArg(GalleryLikeEventType.TOGGLETHUMSBS_NONE, result.identifier, result.state));
      this.serviceBusEvent.next(new GalleryLikeEventArg(GalleryLikeEventType.TOGGLETHUMSBS, result.identifier, result.state));
    }

    this.isLoadingLike = false;
  }

  async setInitialGalleryLike(): Promise<void> {
    const mylikes = await this.gallerLikeService.getMyLikes([this.galleryToView.id]);
    this.galleryToView.likes = mylikes;
  }

  listenForViewPrompt(): void {
    const sub = this.serviceBusEvent.subscribe({
      next: (e: GalleryLikeEventArg) => {
        if (e.getSubType() === GalleryLikeEventType.PROMPTVIEWED_INTERNAL) {
          console.log('From single gallery');
          const gallery = this.galleryToUse.find(x => x.id.toString() === e.identifier);

          

          if (gallery && gallery.id == this.galleryToView.id) {
            this.galleryToView.viewsCount++;
          }
        }
      },
      error: (error) => {
        console.log(error);
      }
    });

    this.subscriptions.push(sub);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(x => x.unsubscribe());
  }

}
