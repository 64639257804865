import { ImageService } from './../../../../core/service/crud/impl/image.service';
import { NotificationService } from './../../../../core/service/notification.service';
import { GalleryStatus } from './../../../../core/model/gallery';
import { AuthService } from './../../../../core/service/auth.service';
import { GalleryService } from './../../../../core/service/crud/impl/gallery.service';
import { FieldConfig } from './../../../../core/utils/field-config';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { Image } from './../../../../core/model/image';

@Component({
  selector: 'of-gallery-create',
  templateUrl: './gallery-create.component.html',
  styleUrls: ['./gallery-create.component.scss'],
  providers: [
    ImageService,
  ]
})
export class GalleryCreateComponent implements OnInit {

  @Output() close = new EventEmitter();

  formGroup: FormGroup;
  beginUpload = new Subject<string>();
  
  public error: Observable<string>;
  public imageModel: Partial<FieldConfig> = {
      translateKey: 'Upload gallery image',
      fieldType: 'upload',
      required: true,
      uploadConfig: {showPreview: true, maxFileSize: 1, autoUpload: false, accept: 'image/*'},
      fieldClass: 'form__field--full',
      propertyName: 'image',
  };

  isFormLoading;
  hasImageError = true;

  constructor(
    private fb: FormBuilder,
    private galleryService: GalleryService,
    private imageService: ImageService,
    private authservice: AuthService,
    private notificationService: NotificationService
  ) { }

  ngOnInit(): void {
    this.initializeForm();
  }

  closeModal(): void {
    this.close.emit();
  }

  initializeForm(): void {
    this.formGroup = this.fb.group({
      prompt: [null, [Validators.required, Validators.minLength(3), Validators.maxLength(250)]],
      image: [null]
    });
  }

  public isValid(name: string) {
    return this.formGroup.get(name).touched && this.formGroup.get(name).invalid;
  }

  public getErrors(name: string) {
      const keys = [];
      for (const key in this.formGroup.get(name).errors) {
          keys.push(key);
      }
      return keys;
  }

  public imageErrors(errors) {
    if (errors) {
      this.hasImageError = true;
    } else {
      this.hasImageError = false;
    }
  }

  notifyUpload(): void {
    this.isFormLoading = true;
    this.beginUpload.next(Math.random().toString());
  }

  public async submitForm(uploaded): Promise<void> {

    if (!uploaded) { this.notificationService.notify('It seems something went wrong, kindly try again later'); }

    // Save the image first
    let newImage: Image;
    try {
      newImage = await this.imageService.createRecord({
        url: this.formGroup.value.image,
      }).toPromise();
    } catch (error) {
      this.notificationService.notify('It seems something went wrong with uploading the image, kindly try again later');
      return;
    }
    
    try {
      await this.galleryService.createRecord({ 
        imageId: newImage.id, 
        prompt: this.formGroup.value.prompt, 
        authorId: this.authservice.getUserId(),
        status: GalleryStatus.PENDING
      }).toPromise();

      this.notificationService.notify('Thanks for the prompt, you will get a notification after review');
      this.close.emit();
    } catch (error) {
      this.notificationService.notify('It seems something went wrong, kindly try again later');
    
    } finally {
      this.isFormLoading = false;
    }
  }
}
