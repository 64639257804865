import { AfterViewInit, Component, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ModelService } from '../../../../core/service/model.service';
import { ListConfig } from '../list-config';
import { ScrollService } from '../../../../core/service/scroll.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'of-infinite-list-with-no-cdk',
  templateUrl: './infinite-list-with-no-cdk.component.html',
  styleUrls: ['./infinite-list-with-no-cdk.component.scss']
})
export class InfiniteListWithNoCdkComponent implements OnInit, OnDestroy {

  @Input() isLoading;
  @Input() onCustomAction: any;
  @Input() isActionDisabled: (ele: any, actionElement: any) => boolean;
  @Input() config: ListConfig = new ListConfig();
  @Input() elements: any[] = [];

  @Output() fetchMoreData = new EventEmitter();

  private mainHeight = 1000;

  private subs: Subscription[] = [];

  constructor(
    private scrollService: ScrollService,
  ) {
  }

  
  ngOnInit(): void {
    this.listenToScroll();
    this.listenForMainHeight();
  }
  
  ngOnDestroy(): void {
    this.subs.forEach(x => x.unsubscribe());
  }

  private listenForMainHeight() {
    const sub = this.scrollService.scrollHeightY$.subscribe({
        next: (data) => {
            this.mainHeight = data;
        }
    });

    this.subs.push(sub);
  }

  private listenToScroll() {
    const sub = this.scrollService.scrollY$.subscribe({
        next: (data) => {
          
            if ((this.mainHeight - data) <= 10) {

              if (this.isLoading) { return; }

              this.fetchMoreData.emit();

              this.scrollService.updateScrollYHeight(this.mainHeight + 100);
            }
        }
    });

    this.subs.push(sub);
  }
    
  // This is used for mobile view
  @HostListener("window:scroll", ["$event"])
  onWindoScroll(): void {
    //In chrome and some browser scroll is given to body tag
    let pos = (document.documentElement.scrollTop || document.body.scrollTop) + document.documentElement.offsetHeight;
    let max = document.documentElement.scrollHeight;
    // pos/max will give you the distance between scroll bottom and and bottom of screen in percentage.
    if((max - pos) <= 10)  
      {
        if (this.isLoading) {  return;  }

        this.fetchMoreData.emit();
    }
  }

}
