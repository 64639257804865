import {Injectable} from '@angular/core';
import {concatMap} from 'rxjs/operators';
import {EMPTY, from} from 'rxjs';
import {of} from 'rxjs/internal/observable/of';

@Injectable({
    providedIn: 'root'
})
export class SocialServiceService {

    constructor() {
    }

    public getFacebookToken() {
        // login with facebook and return observable with fb access token on success
        // @ts-ignore
        return from(new Promise<fb.StatusResponse>(resolve => FB.login(resolve, {scope: 'public_profile,email'})))
            .pipe(concatMap(({authResponse}) => {
                if (!authResponse) {
                    return EMPTY;
                }
                return of(authResponse.accessToken);
            }));
    }
}
