import {Injectable} from '@angular/core';
import * as blobUtil from 'blob-util';

@Injectable()
export class BlobService {

    private clearBlobData(reader: FileReader): void {
        reader.onload = null;
        reader.onerror = null;
        reader.onabort = null;
    }

    public blobToDataURL(blob: Blob): Promise<string> {
        return new Promise<string>((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = (_e) => {
                this.clearBlobData(reader);
                resolve(reader.result as string);
            };
            reader.onerror = (_e) => {
                this.clearBlobData(reader);
                reject(reader.error);
            };
            reader.onabort = (_e) => {
                this.clearBlobData(reader);
                reject(new Error('Read aborted'));
            };
            reader.readAsDataURL(blob);
        });
    }

    public dataURLToBlobUrl(dataURL) {
        const blob = this.dataURLToBlob(dataURL);
        return blobUtil.createObjectURL(blob);
    }

    public revokeUrl(url: string) {
        blobUtil.revokeObjectURL(url);
    }

    public stringToBlob(content: string) {
        return new Blob([content], {type: 'text/plain'});
    }

    private dataURLToBlob(data: string) {
        return blobUtil.dataURLToBlob(data);
    }

    public base64StringToBlob(base64Img) {
        return blobUtil.base64StringToBlob(base64Img);
    }

    public async blobToUrl(blob) {
        return blobUtil.createObjectURL(blob);
    }

    base64ToBlob(base64Data: string, contentType: string = 'image/png'): Blob {
        // Remove the prefix (if present) from the base64 string
        const base64: string = base64Data.split(';base64,')[1];

        // Convert base64 to binary
        const byteCharacters: string = atob(base64);
        const byteNumbers: number[] = new Array(byteCharacters.length);

        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);

        // Create blob
        return new Blob([byteArray], {type: contentType});
    }
}
