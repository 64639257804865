
<section class="item">
    <img [src]="mission.iconUrl" [alt]="mission.name">
    <div>
        <div class="item__info">
            <div class="item__info-header">
                <strong class="item__info-title">{{mission.name}} <small *ngIf="mission.hidden === true"> - HIDDEN</small></strong>
            </div>
        </div>

        <div class="item__body">
            <p class="item__info--overview" *ngIf="mission.overview">{{ mission.overview }}</p>
            <div fxLayout="row" fxLayoutAlign="flex-start center">
                <strong class="item__price">
                    {{mission.reward}}
                    <i class="icon icon__svg">
                        <svg width="16" viewBox="0 0 20 20" fill="currentColor"
                             xmlns="http://www.w3.org/2000/svg">
                            <path d="M4.528 3.47h2.196l-.897 3.413H1.842L4.528 3.47ZM13.255 6.883h-6.22L7.93 3.47h4.428l.896 3.413ZM18.445 6.883h-3.986l-.896-3.413h2.154l2.728 3.413ZM5.845 8.054l3.234 8.65-7.204-8.65h3.97ZM13.197 8.054l-3.055 9.089-3.055-9.09h6.11ZM18.415 8.054l-7.22 8.688 3.249-8.688h3.97Z"></path>
                        </svg>
                    </i>
                </strong>
            </div>
        </div>
    </div>
</section>