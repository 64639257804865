import { Attribute, BelongsTo, HasMany, JsonApiModel, JsonApiModelConfig } from "angular2-jsonapi";
import { TimestampConverter } from "../logic/timestamp-converter";
import { Field, ValueListConfig } from "../utils/field-config";
import { User } from "./user";
import { Image } from "./image";
import { Gallery } from "./gallery";

export enum GalleryImageLikeState {
    THUMBS_UP,
    THUMPS_DOWN
}

@JsonApiModelConfig({type: 'galleryImageLike'})
export class GalleryLike extends JsonApiModel {
    
    @Attribute()
    id: string;

    @Attribute()
    @Field({
        translateKey: 'User',
        fieldClass: 'form__field--full',
    })
    public userId: string;

    @Attribute()
    @Field({
        translateKey: 'Gallery',
        fieldClass: 'form__field--full'
    })
    public galleryImageId: string;

    @Attribute()
    @Field({
        translateKey: 'Nature',
        fieldClass: 'form__field--full'
    })
    public nature: GalleryImageLikeState.THUMBS_UP | GalleryImageLikeState.THUMPS_DOWN;

    @BelongsTo()
    public user: User;

    @BelongsTo()
    public galleryImage: Gallery;

    @Attribute({converter: new TimestampConverter()})
    deletedAt: Date;

    @Attribute({converter: new TimestampConverter()})
    createdAt: Date;

    @Attribute({converter: new TimestampConverter()})
    updatedAt: Date;
}