import {UserRewardStat} from '../../../../core/model/user-reward-stat';
import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'of-leaderboard-winner',
    templateUrl: './leaderboard-winner.component.html',
    styleUrls: ['./leaderboard-winner.component.scss']
})
export class LeaderboardWinnerComponent implements OnInit {

    @Input() public index = 0;
    @Input() public userReward: UserRewardStat;
    @Input() public allTime = true;

    constructor() {
    }

    ngOnInit(): void {

        if (!this.userReward.user.firstName && !this.userReward.user.lastName) {
            this.userReward.user.displayName = `User ${this.userReward.user.id.slice(-6)}`;
            return;
        }

        if (this.userReward.user.firstName !== 'undefined' && !this.userReward.user.lastName) {
            this.userReward.user.displayName = this.userReward.user.firstName;
            return;
        }

        if (!this.userReward.user.firstName && this.userReward.user.lastName !== 'undefined') {
            this.userReward.user.displayName = this.userReward.user.lastName;
            return;
        }

        this.userReward.user.displayName = this.userReward.user.firstName + ' ' + this.userReward.user.lastName;
    }

}
