import { Component, ElementRef, Input, NgZone, OnInit, ViewChild } from '@angular/core';
import * as RecordRTC from 'recordrtc';
import { ConceptFormModel, PropertyFormModel } from '../../../../service/ontology-form.service';
import { DomSanitizer } from '@angular/platform-browser';
import { BlobService } from '../../../../service/blob.service';


@Component({
    selector: 'of-microphone',
    templateUrl: './microphone.component.html',
    styleUrls: ['./microphone.component.scss'],
    providers: [BlobService]
})
export class MicrophoneComponent implements OnInit {

    @Input() model: ConceptFormModel;
    @Input() property: PropertyFormModel;
    @Input() readonly: boolean;

    @ViewChild('waveEl') waveEl: ElementRef;
    @ViewChild('svgEl') svgEl: ElementRef;
    @ViewChild('audioWave') audioWave: ElementRef;
    @ViewChild('audioSource') audioSource: ElementRef;

    public waveSound;
    public record;
    public showWave = false;
    public recording = false;
    public url;
    public error;
    private interval;
    public canTranslate;
    public translateValue = 0;
    public isPlaying = false;

    public blobDataUrl;

    constructor(private domSanitizer: DomSanitizer, private zone: NgZone, private blobService: BlobService) {
    }

    sanitize(url: string) {
        return this.domSanitizer.bypassSecurityTrustUrl(url);
    }

    getAvailableMediaInputs(): Promise<MediaStream> {
        if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
            return Promise.reject('getUserMedia() not supported.');
        }

        return new Promise((resolve, reject) => {
            const mediaConstraints = {
                video: false,
                audio: true
            };

            navigator.mediaDevices.getUserMedia(mediaConstraints)
                .then((stream: MediaStream) => {
                    resolve(stream);
                })
                .catch(err => {
                    reject(err.message || err);
                });
        });
    }

    initiateRecording() {
        this.recording = true;
        this.showWave = true;
        this.start();
        if (this.url) {
            this.url = undefined;
            this.blobDataUrl = undefined;
        }
        this.zone.runOutsideAngular(() => {
            this.getAvailableMediaInputs()
                .then(this.successCallback.bind(this), this.errorCallback.bind(this))
        })
    }

    async successCallback(stream) {
        const options = {
            mimeType: 'audio/wav',
            numberOfAudioChannels: 1
        };
        const StereoAudioRecorder = RecordRTC.StereoAudioRecorder;
        this.record = new StereoAudioRecorder(stream, options);
        this.record.record();
    }

    stopRecording() {
        this.recording = false;
        this.showWave = false;
        this.record.stop(this.processRecording.bind(this));
        this.stop();
    }

    async processRecording(blob) {
        this.url = URL.createObjectURL(blob);
        this.blobDataUrl = await this.blobService.blobToDataURL(blob);
    }

    errorCallback(error) {
        console.error('Can not record audio in your browser', error);
    }

    remove() {
        this.url = undefined;
        this.isPlaying = false;
    }

    ngOnInit() {
    }

    generateWave() {
        const config = {
            items: [],
            min_distance: 7,
            max_distance: 28,
            line_height: 32,
            line_spacing: 4,
            line_width: 2,
            line_color: '#ffffff'
        };

        if (this.svgEl) {
            this.svgEl.nativeElement.innerHTML = '';
        }
        let waveCount = 1;

        const randomSVG = (index) => {
            config.items = Array(config.max_distance - config.min_distance + 1).fill(1).map((_, idx) => 7 + idx);
            const svgns = 'http://www.w3.org/2000/svg';

            let y1 = config.items[Math.floor(Math.random() * config.items.length)];
            let y2 = config.line_height - y1;

            if (y1 === y2) {
                y2 += 2;
                y1 -= 2;
            }

            const line = document.createElementNS(svgns, 'line');
            const x = index * config.line_spacing;

            line.setAttributeNS(null, 'x1', x.toString());
            line.setAttributeNS(null, 'y1', y1.toString());
            line.setAttributeNS(null, 'x2', x.toString());
            line.setAttributeNS(null, 'y2', y2.toString());
            line.setAttributeNS(null, 'stroke-width', config.line_width.toString());
            line.setAttributeNS(null, 'stroke', config.line_color);

            if (this.canTranslate) {
                this.translateValue += 4;
            }

            this.shouldMove(index);
            this.svgEl.nativeElement.appendChild(line);
        }

        this.interval = setInterval(() => {
            randomSVG(waveCount++);
        }, 142);
    }

    shouldMove(waveIndex) {
        const elWidth = this.audioWave.nativeElement.clientWidth;
        const nrOfElem = elWidth / 4;
        if (waveIndex > nrOfElem) {
            this.canTranslate = true;
        }
    }

    start() {
        this.generateWave();
    }

    stop() {
        clearInterval(this.interval);
        this.canTranslate = false;
        this.translateValue = 0;
    }

    play() {
        if (this.audioSource && this.url) {
            this.isPlaying = true;
            this.audioSource.nativeElement.play();
        }
    }

    pause() {
        if (this.audioSource && this.url) {
            this.isPlaying = false;
            this.audioSource.nativeElement.pause();
        }
    }
}
