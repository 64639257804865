
<section ofClickOutside (clickOutside)="closePanel()" *ngIf="template" class="more-info-container">

    <header>
        <div class="back" style="cursor: pointer;" (click)="closePanel()">
            <span class="icon icon__svg">
                <i class="ri-arrow-left-line"></i>
            </span>
            <span class="text">
                Go Back
            </span>
        </div>
    </header>

    <main>
        <!-- <ng-content></ng-content> -->
        <ng-container *ngTemplateOutlet="template">
        </ng-container>
    </main>

</section>