import {
    Component,
    OnInit
} from '@angular/core';
import {NotificationService} from '../../../core/service/notification.service';

@Component({
    selector: 'of-notification-loading',
    templateUrl: './notification-loading.component.html',
    styleUrls: ['./notification-loading.component.scss'],
    providers: []
})
export class NotificationLoadingComponent implements OnInit {
    constructor(public notificationService: NotificationService) {
    }

    ngOnInit() {
    }
}
