<ng-container *ngIf="loading; else nftTemplate">
    <mat-spinner diameter="60"></mat-spinner>
</ng-container>
<ng-template #nftTemplate>
    <div class="list-grid" *ngIf="list && list.length > 0; else noNftTemplate">
        <ng-container *ngFor="let nft of list">
            <of-item-nft [media]="media" class="list-grid__item" [userId]="loggedUserId" [item]="nft"></of-item-nft>
        </ng-container>
    </div>

    <ng-template #noNftTemplate>
        <h3>You have no NFTs</h3>
    </ng-template>
</ng-template>

