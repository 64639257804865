<!--of-wizard__section3. Openfabric
  -->

<div class="of-wizard__section">
    <div class="of-wizard__section-header">
        <h3>Prompt examples</h3>
    </div>

    <div class="of-wizard__section-body">
        <div class="of-wizard__item" *ngFor="let item of prompts">
            <figure *ngIf="item.image">
                <img src="{{item.image}}" alt="{{item.title}}">
            </figure>

            <div class="of-wizard__item-footer">
                <h4 *ngIf="item.title">{{item.title}}</h4>
                <p *ngIf="item.prompt">{{item.prompt}}</p>
            </div>
        </div>
    </div>
</div>
