import { Component, Input, OnInit } from '@angular/core';
import { NavigationOptions } from 'swiper/types';

@Component({
  selector: 'of-image-slider',
  templateUrl: './image-slider.component.html',
  styleUrls: ['./image-slider.component.scss']
})
export class ImageSliderComponent implements OnInit {

  @Input() images: any[] = [];

  public breakpoints = {'320': {slidesPerView: 1, spaceBetween: 0}, '768': {slidesPerView: 1.2, spaceBetween: 20}};

  public navigation: NavigationOptions = {
    nextEl: '.swiper__button-next',
    prevEl: '.swiper__button-prev',
};

  constructor() { }

  ngOnInit(): void {
  }

}
