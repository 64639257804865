<div class="of-quick__links">
    <h2 class="of-section__title">{{ 'quick_links.title' | translate }}</h2>

    <ul class="of-quick__links-list">
        <li>
            <a href="https://openfabric.ai/" target="_blank">
                <i class="icon icon__svg">
                    <of-svgs name="globe" width="25px" ></of-svgs>
                </i>
                Website
            </a>
        </li>
        <li>
            <a href="https://t.me/OpenFabricAI" target="_blank">
                <i class="icon icon__svg">
                    <of-svgs name="telegram" width="20px" ></of-svgs>
                </i>
                Telegram
            </a>
        </li>
        <li>
            <a href="https://twitter.com/openfabricai" target="_blank">
                <i class="icon icon__svg">
                    <of-svgs name="twitter" width="20px" ></of-svgs>
                </i>
                Twitter
            </a>
        </li>
        <li>
            <a href="https://www.youtube.com/channel/UC_f7L5IUFjQdlVK_y45OHNw" target="_blank">
                <i class="icon icon__svg">
                    <of-svgs name="youtube" width="20px" ></of-svgs>
                </i>
                YouTube
            </a>
        </li>
        <li>
            <a href="https://www.linkedin.com/company/openfabricai" target="_blank">
                <i class="icon icon__svg">
                    <of-svgs name="linkedin" width="20px" ></of-svgs>
                </i>
                Linkedin
            </a>
        </li>
        <li>
            <a href="https://openfabric.ai/blog" target="_blank">
                <i class="icon icon__svg">
                    <of-svgs name="news" width="20px" ></of-svgs>
                </i>
                News
            </a>
        </li>
    </ul>
</div>
