import { NotificationService } from './../../service/notification.service';
import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { FieldConfig } from "../../utils/field-config";
import { UploadConfig, UploaderService } from "../../service/uploader.service";
import { FormGroup } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { Observable } from "rxjs/Observable";
import { Subject, Subscription } from 'rxjs';

@Component({
    selector: 'of-upload',
    templateUrl: './upload.component.html',
    styleUrls: ['./upload.component.scss'],
    providers: [UploaderService],
})
export class UploadComponent implements OnInit, OnDestroy {
    @Input() column: FieldConfig;
    @Input() error: any;
    @Input() form: FormGroup;
    @Input() uploadLabelKey = 'general.upload_file';
    @Input() imageCss = '';
    @Input() figureCss = '';
    @Input() manualUpload: Subject<string>;

    @Output() errorsFound = new EventEmitter();
    @Output() uploadCompleted = new EventEmitter();

    public hasBaseDropZoneOver = false;
    public isLoading = false;

    public base64: string;
    public subscription: Subscription[] = []

    constructor(
        private uploadService: UploaderService,
        public translateService: TranslateService,
        private notificationService: NotificationService
    ) {
    }

    ngOnInit(): void {
        this.listenForManualUpload();
    }

    listenForManualUpload(): void {
        if (!this.manualUpload) { return; }

        const sub = this.manualUpload.subscribe({
            next: (data) => {
                this.beginManualUpload();
            }
        });
        this.subscription.push(sub);
    }

    public onDropResource(fileList: any, column: FieldConfig): void {
        this.validateFileUpload(fileList, column);

        if (column.uploadConfig.autoUpload) {
            this.uploadService.upload(column?.uploadConfig._uploader);
            column.uploadConfig.loading = true;
            this.isLoading = true;
        }
    }

    public canPreviewResource(column: any) {
        return column?.uploadConfig
            && column?.uploadConfig?.showPreview &&
            this.isResourceUploaded(column);
    }

    public isResourceUploaded(column: FieldConfig) {
        return this.getResourcePath(column) !== null;
    }

    public getResourcePath(column: FieldConfig) {
        return <string>this.form.get(column.propertyName.toString()).value;
    }

    public removeResource(column: FieldConfig) {
        this.form.get(column.propertyName.toString()).setValue(null);
        this.base64 = null;
        column.uploadConfig._uploader.clearQueue();
    }

    public fileOverBase(e: any): void {
        this.hasBaseDropZoneOver = e;
    }

    public getUploaderConfiguration(column: FieldConfig) {

        if (!column.uploadConfig) {
            column.uploadConfig = new UploadConfig();
        }

        if (!column.uploadConfig._uploader) {
            column.uploadConfig._uploader = this.uploadService.createFileUploader(column.uploadConfig);

            this.saveResponse(column)
        }
        return column.uploadConfig._uploader;
    }

    getTranslateLabel(label: string): Observable<string> {
        return this.translateService.get(label, {value: ''});
    }

    async validateFileUpload(fileList: any, column: FieldConfig): Promise<void> {
        // for now check file size
        if (column.uploadConfig.maxFileSize && column.uploadConfig._uploader.queue[0].file.size > (column.uploadConfig.maxFileSize * 1000000)) {
            this.error = `File size exceeds ${column.uploadConfig.maxFileSize}MB`;
            this.errorsFound.emit(this.error);
            this.column.uploadConfig._uploader.clearQueue();
            return;
        }

        this.errorsFound.emit(null);

        this.error = null;
        const result = await this.setBase64(column.uploadConfig._uploader.queue[0].file.rawFile);
        this.base64 = result as string;
    }

    setBase64(file: any) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = reject;
        });
     }

    private beginManualUpload(): void {
        try {
            this.column.uploadConfig.loading = true;
            this.isLoading = true;
            this.column.uploadConfig._uploader.queue[0].upload();
            this.saveResponse(this.column);
        } catch (error) {
            this.column.uploadConfig.loading = false;
            this.isLoading = false;
            this.uploadCompleted.emit(false);
        }
    }

    private saveResponse(column: FieldConfig) {
        this.uploadService.subscribe(column.uploadConfig._uploader).subscribe(res => {

            if (!res.success) {
                this.isLoading = false;
                return;
            }

            setTimeout(() => {
                const currentUrlValue = this.form.get(column.propertyName.toString()).value;
                this.form.get(column.propertyName.toString()).setValue(res.url);
                this.isLoading = false;
                // HACK !!! it seems the upload get stuck on  FieldConfig state
                column.uploadConfig._uploader = null;

                if (currentUrlValue != res.url) {
                    this.uploadCompleted.emit(true);
                }
            }, 200)

        });
    }

    ngOnDestroy(): void {
    this.subscription.forEach(x => x.unsubscribe());
    }
}
