import {Router} from '@angular/router';
import {Mission} from './../../../../core/model/mission';
import {PaginateCriteria} from './../../../../core/service/crud/paginate.service';
import {FilterOperator} from './../../../../core/service/crud/search.service';
import {MissionService} from './../../../../core/service/crud/impl/mission.service';
import {Component, OnDestroy, OnInit} from '@angular/core';

@Component({
    selector: 'of-dashboard-slider',
    templateUrl: './dashboard-slider.component.html',
    styleUrls: ['./dashboard-slider.component.scss'],
    providers: [
        MissionService,
    ]
})
export class DashboardSliderComponent implements OnInit, OnDestroy {

    isLoading = false;
    missions: Mission[] = [];

    index = 3;
    interval;

    constructor(
        private missionService: MissionService,
        private router: Router
    ) {
    }

    async ngOnInit() {
        await this.get5LatestMissions();
    }

    async get5LatestMissions(): Promise<void> {
        this.isLoading = true;
        const criteria = new PaginateCriteria(10, 1);

        criteria.addSort('createdAt', 'desc');

        criteria.addFilter('mission.featured', FilterOperator.EQUALS, true);
        criteria.addFilter('mission.hidden', FilterOperator.EQUALS, false);

        const data = await this.missionService.findAll(criteria).toPromise();

        this.missions = data.getModels();

        if (this.missions.length > 0) {
            this.setAnimate();
        }
    }

    checked(event: any, index = 0, mission: Mission) {
        event.preventDefault();
        this.router.navigate(['/mission/detail/' + mission.id]);
    }

    slide(increment: number, stopAnimate = false) {
        const index = this.index + increment;

        if (stopAnimate) {
            clearInterval(this.interval);
            this.setAnimate();
        }

        if (index > 5) {
            this.index = 1;
            return;
        }

        if (index < 1) {
            this.index = 5;
            return;
        }

        this.index = index;
    }

    setAnimate(): void {
        this.interval = setInterval(() => {
            this.slide(1);
        }, 5000);
    }

    ngOnDestroy(): void {
        clearInterval(this.interval);
    }

}
