import { NotificationService } from './../../../core/service/notification.service';
import { User } from '../../../core/model/user';
import { ResponsiveService } from '../../../core/service/responsive.service';
import { PermissionService } from '../../../core/service/permission.service';
import { Component, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';

@Component({
  selector: 'of-mobile-navigation-menu',
  templateUrl: './mobile-navigation-menu.component.html',
  styleUrls: ['./mobile-navigation-menu.component.scss'],
  providers: [PermissionService, NotificationService]
})
export class MobileNavigationMenuComponent implements OnInit {

  @Input() user: User;
  @Input() userMenu: TemplateRef<any>;
  @Output() close = new EventEmitter();


  public isMobile = false;
  private sub: any;

  constructor(public permission: PermissionService,
    private responsiveService: ResponsiveService,
    private notificationService: NotificationService,
    ) { }


  ngOnInit() {
    this.sub = this.responsiveService.isMobile$.subscribe(res => this.isMobile = res)
  }

  login() {
    this.notificationService.showRegister();
  }

  ngOnDestroy() {
      this.sub.unsubscribe();
  }

  closeMenu() {
    this.close.emit();
  }
}
