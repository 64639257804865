
<of-main-page
    #mainPageComponent
    [service]="service"
    [prototype]="prototype"
    [contextualTranslationService]="translationService"
    [searchConfig]="{
        display: false
    }"
    [paginationConfig]="{
        pageSize: 100,
        display: true
      }"
    [permissionConfig]="{
        view: 'VIEW_MISSION'
    }"
    [listConfig]="{
        displayMode:'grid',
        templateListItemRef:missionItemTemplate,
        templateNoDataRef: missionNoDataTemplate,
        defaultSort:{active:'rewardTotal', direction:'asc'},
        searchContributor:mainListSearchContributor,
        postProcessData: postProcessData
  }"

>
<div class="main-top">
    <div class="of-section__title-header">
        <h2 class="of-section__title">Missions</h2>
        <mat-form-field>
            <mat-label>Mission state</mat-label>
            <mat-select 
                [(value)]="missionState"
                (selectionChange)="mainPageComponent?.list?.search()"
                >
              <mat-option selected value="all">All</mat-option>
              <mat-option [value]="true">Complete</mat-option>
              <mat-option [value]="false">Incomplete</mat-option>
              <!-- <mat-option value="FAILED">Failed</mat-option>
              <mat-option value="NOT_STARTED">Not Started</mat-option> -->
            </mat-select>
          </mat-form-field>
    </div>
</div>
</of-main-page>

<!-- ########################################################## -->
<!--            LIST ITEM                                       -->
<!-- ########################################################## -->
<ng-template #missionItemTemplate let-item="item">
    <of-list-user-mission [item]="item"></of-list-user-mission>
</ng-template>

<!-- ########################################################## -->
<!--            EMPTY LIST                                      -->
<!-- ########################################################## -->
<ng-template #missionNoDataTemplate>
    <div class="list__no-data--secondary">
        <i class="icon icon__svg">
            <of-svgs name="search_file" width="32px"></of-svgs>
        </i>

        <p>{{'list.no_data_missions' | translate}}</p>
    </div>
</ng-template>