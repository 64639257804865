<ng-container *ngIf="permission.can('CONNECT_WALLET')">
    <ng-container *ngIf="web3Provider">
        <button type="button"
                class="of-button of-button__web3 of-button__web3-connect"
                (click)="connectWallet()"
                *ngIf="!address">
            <i class="icon icon__svg mr--10 ml--0">
                <svg xmlns="http://www.w3.org/2000/svg" width="16px" viewBox="0 0 507.83 470.86">
                    <defs>
                        <style>.a {
                            fill: #e2761b;
                            stroke: #e2761b;
                        }

                        .a, .b, .c, .d, .e, .f, .g, .h, .i, .j {
                            stroke-linecap: round;
                            stroke-linejoin: round;
                        }

                        .b {
                            fill: #e4761b;
                            stroke: #e4761b;
                        }

                        .c {
                            fill: #d7c1b3;
                            stroke: #d7c1b3;
                        }

                        .d {
                            fill: #233447;
                            stroke: #233447;
                        }

                        .e {
                            fill: #cd6116;
                            stroke: #cd6116;
                        }

                        .f {
                            fill: #e4751f;
                            stroke: #e4751f;
                        }

                        .g {
                            fill: #f6851b;
                            stroke: #f6851b;
                        }

                        .h {
                            fill: #c0ad9e;
                            stroke: #c0ad9e;
                        }

                        .i {
                            fill: #161616;
                            stroke: #161616;
                        }

                        .j {
                            fill: #763d16;
                            stroke: #763d16;
                        }</style>
                    </defs>
                    <polygon class="a" points="482.09 0.5 284.32 147.38 320.9 60.72 482.09 0.5"/>
                    <polygon class="b" points="25.54 0.5 221.72 148.77 186.93 60.72 25.54 0.5"/>
                    <polygon class="b"
                             points="410.93 340.97 358.26 421.67 470.96 452.67 503.36 342.76 410.93 340.97"/>
                    <polygon class="b" points="4.67 342.76 36.87 452.67 149.57 421.67 96.9 340.97 4.67 342.76"/>
                    <polygon class="b"
                             points="143.21 204.62 111.8 252.13 223.7 257.1 219.73 136.85 143.21 204.62"/>
                    <polygon class="b"
                             points="364.42 204.62 286.91 135.46 284.32 257.1 396.03 252.13 364.42 204.62"/>
                    <polygon class="b" points="149.57 421.67 216.75 388.87 158.71 343.55 149.57 421.67"/>
                    <polygon class="b" points="290.88 388.87 358.26 421.67 348.92 343.55 290.88 388.87"/>
                    <polygon class="c"
                             points="358.26 421.67 290.88 388.87 296.25 432.8 295.65 451.28 358.26 421.67"/>
                    <polygon class="c"
                             points="149.57 421.67 212.18 451.28 211.78 432.8 216.75 388.87 149.57 421.67"/>
                    <polygon class="d" points="213.17 314.54 157.12 298.04 196.67 279.95 213.17 314.54"/>
                    <polygon class="d" points="294.46 314.54 310.96 279.95 350.71 298.04 294.46 314.54"/>
                    <polygon class="e" points="149.57 421.67 159.11 340.97 96.9 342.76 149.57 421.67"/>
                    <polygon class="e" points="348.72 340.97 358.26 421.67 410.93 342.76 348.72 340.97"/>
                    <polygon class="e"
                             points="396.03 252.13 284.32 257.1 294.66 314.54 311.16 279.95 350.91 298.04 396.03 252.13"/>
                    <polygon class="e"
                             points="157.12 298.04 196.87 279.95 213.17 314.54 223.7 257.1 111.8 252.13 157.12 298.04"/>
                    <polygon class="f" points="111.8 252.13 158.71 343.55 157.12 298.04 111.8 252.13"/>
                    <polygon class="f" points="350.91 298.04 348.92 343.55 396.03 252.13 350.91 298.04"/>
                    <polygon class="f"
                             points="223.7 257.1 213.17 314.54 226.29 382.31 229.27 293.07 223.7 257.1"/>
                    <polygon class="f"
                             points="284.32 257.1 278.96 292.87 281.34 382.31 294.66 314.54 284.32 257.1"/>
                    <polygon class="g"
                             points="294.66 314.54 281.34 382.31 290.88 388.87 348.92 343.55 350.91 298.04 294.66 314.54"/>
                    <polygon class="g"
                             points="157.12 298.04 158.71 343.55 216.75 388.87 226.29 382.31 213.17 314.54 157.12 298.04"/>
                    <polygon class="h"
                             points="295.65 451.28 296.25 432.8 291.28 428.42 216.35 428.42 211.78 432.8 212.18 451.28 149.57 421.67 171.43 439.55 215.75 470.36 291.88 470.36 336.4 439.55 358.26 421.67 295.65 451.28"/>
                    <polygon class="i"
                             points="290.88 388.87 281.34 382.31 226.29 382.31 216.75 388.87 211.78 432.8 216.35 428.42 291.28 428.42 296.25 432.8 290.88 388.87"/>
                    <polygon class="j"
                             points="490.44 156.92 507.33 75.83 482.09 0.5 290.88 142.41 364.42 204.62 468.37 235.03 491.43 208.2 481.49 201.05 497.39 186.54 485.07 177 500.97 164.87 490.44 156.92"/>
                    <polygon class="j"
                             points="0.5 75.83 17.39 156.92 6.66 164.87 22.56 177 10.44 186.54 26.34 201.05 16.4 208.2 39.26 235.03 143.21 204.62 216.75 142.41 25.54 0.5 0.5 75.83"/>
                    <polygon class="g"
                             points="468.37 235.03 364.42 204.62 396.03 252.13 348.92 343.55 410.93 342.76 503.36 342.76 468.37 235.03"/>
                    <polygon class="g"
                             points="143.21 204.62 39.26 235.03 4.67 342.76 96.9 342.76 158.71 343.55 111.8 252.13 143.21 204.62"/>
                    <polygon class="g"
                             points="284.32 257.1 290.88 142.41 321.1 60.72 186.93 60.72 216.75 142.41 223.7 257.1 226.09 293.27 226.29 382.31 281.34 382.31 281.74 293.27 284.32 257.1"/>
                </svg>
            </i>
            <span>Connect Wallet</span>
        </button>
    </ng-container>

    <ng-container *ngIf="!web3Provider">
        <a href="{{dappurl}}" class="of-button of-button__web3 of-button__web3-connect">
            <i class="icon icon__svg mr--10 ml--0">
                <svg xmlns="http://www.w3.org/2000/svg" width="16px" viewBox="0 0 507.83 470.86">
                    <defs>
                        <style>.a {
                            fill: #e2761b;
                            stroke: #e2761b;
                        }

                        .a, .b, .c, .d, .e, .f, .g, .h, .i, .j {
                            stroke-linecap: round;
                            stroke-linejoin: round;
                        }

                        .b {
                            fill: #e4761b;
                            stroke: #e4761b;
                        }

                        .c {
                            fill: #d7c1b3;
                            stroke: #d7c1b3;
                        }

                        .d {
                            fill: #233447;
                            stroke: #233447;
                        }

                        .e {
                            fill: #cd6116;
                            stroke: #cd6116;
                        }

                        .f {
                            fill: #e4751f;
                            stroke: #e4751f;
                        }

                        .g {
                            fill: #f6851b;
                            stroke: #f6851b;
                        }

                        .h {
                            fill: #c0ad9e;
                            stroke: #c0ad9e;
                        }

                        .i {
                            fill: #161616;
                            stroke: #161616;
                        }

                        .j {
                            fill: #763d16;
                            stroke: #763d16;
                        }</style>
                    </defs>
                    <polygon class="a" points="482.09 0.5 284.32 147.38 320.9 60.72 482.09 0.5"/>
                    <polygon class="b" points="25.54 0.5 221.72 148.77 186.93 60.72 25.54 0.5"/>
                    <polygon class="b"
                             points="410.93 340.97 358.26 421.67 470.96 452.67 503.36 342.76 410.93 340.97"/>
                    <polygon class="b" points="4.67 342.76 36.87 452.67 149.57 421.67 96.9 340.97 4.67 342.76"/>
                    <polygon class="b"
                             points="143.21 204.62 111.8 252.13 223.7 257.1 219.73 136.85 143.21 204.62"/>
                    <polygon class="b"
                             points="364.42 204.62 286.91 135.46 284.32 257.1 396.03 252.13 364.42 204.62"/>
                    <polygon class="b" points="149.57 421.67 216.75 388.87 158.71 343.55 149.57 421.67"/>
                    <polygon class="b" points="290.88 388.87 358.26 421.67 348.92 343.55 290.88 388.87"/>
                    <polygon class="c"
                             points="358.26 421.67 290.88 388.87 296.25 432.8 295.65 451.28 358.26 421.67"/>
                    <polygon class="c"
                             points="149.57 421.67 212.18 451.28 211.78 432.8 216.75 388.87 149.57 421.67"/>
                    <polygon class="d" points="213.17 314.54 157.12 298.04 196.67 279.95 213.17 314.54"/>
                    <polygon class="d" points="294.46 314.54 310.96 279.95 350.71 298.04 294.46 314.54"/>
                    <polygon class="e" points="149.57 421.67 159.11 340.97 96.9 342.76 149.57 421.67"/>
                    <polygon class="e" points="348.72 340.97 358.26 421.67 410.93 342.76 348.72 340.97"/>
                    <polygon class="e"
                             points="396.03 252.13 284.32 257.1 294.66 314.54 311.16 279.95 350.91 298.04 396.03 252.13"/>
                    <polygon class="e"
                             points="157.12 298.04 196.87 279.95 213.17 314.54 223.7 257.1 111.8 252.13 157.12 298.04"/>
                    <polygon class="f" points="111.8 252.13 158.71 343.55 157.12 298.04 111.8 252.13"/>
                    <polygon class="f" points="350.91 298.04 348.92 343.55 396.03 252.13 350.91 298.04"/>
                    <polygon class="f"
                             points="223.7 257.1 213.17 314.54 226.29 382.31 229.27 293.07 223.7 257.1"/>
                    <polygon class="f"
                             points="284.32 257.1 278.96 292.87 281.34 382.31 294.66 314.54 284.32 257.1"/>
                    <polygon class="g"
                             points="294.66 314.54 281.34 382.31 290.88 388.87 348.92 343.55 350.91 298.04 294.66 314.54"/>
                    <polygon class="g"
                             points="157.12 298.04 158.71 343.55 216.75 388.87 226.29 382.31 213.17 314.54 157.12 298.04"/>
                    <polygon class="h"
                             points="295.65 451.28 296.25 432.8 291.28 428.42 216.35 428.42 211.78 432.8 212.18 451.28 149.57 421.67 171.43 439.55 215.75 470.36 291.88 470.36 336.4 439.55 358.26 421.67 295.65 451.28"/>
                    <polygon class="i"
                             points="290.88 388.87 281.34 382.31 226.29 382.31 216.75 388.87 211.78 432.8 216.35 428.42 291.28 428.42 296.25 432.8 290.88 388.87"/>
                    <polygon class="j"
                             points="490.44 156.92 507.33 75.83 482.09 0.5 290.88 142.41 364.42 204.62 468.37 235.03 491.43 208.2 481.49 201.05 497.39 186.54 485.07 177 500.97 164.87 490.44 156.92"/>
                    <polygon class="j"
                             points="0.5 75.83 17.39 156.92 6.66 164.87 22.56 177 10.44 186.54 26.34 201.05 16.4 208.2 39.26 235.03 143.21 204.62 216.75 142.41 25.54 0.5 0.5 75.83"/>
                    <polygon class="g"
                             points="468.37 235.03 364.42 204.62 396.03 252.13 348.92 343.55 410.93 342.76 503.36 342.76 468.37 235.03"/>
                    <polygon class="g"
                             points="143.21 204.62 39.26 235.03 4.67 342.76 96.9 342.76 158.71 343.55 111.8 252.13 143.21 204.62"/>
                    <polygon class="g"
                             points="284.32 257.1 290.88 142.41 321.1 60.72 186.93 60.72 216.75 142.41 223.7 257.1 226.09 293.27 226.29 382.31 281.34 382.31 281.74 293.27 284.32 257.1"/>
                </svg>
            </i>
            <span>Connect Wallet</span>
        </a>
    </ng-container>

    <ng-container *ngIf="address">
        <ng-container *ngIf="!networkError">
            <div class="of-button of-button__web3 of-button__web3-network"
                 (click)="showNetworkModal()"
                 *ngIf="network">
                <span>{{network}}</span>

                <i class="icon icon__svg">
                    <svg width="16" height="8" viewBox="0 0 16 8" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                        <path d="M1.88 0L8 4.94879L14.12 0L16 1.531L8 8L0 1.531L1.88 0Z"
                              fill="currentColor"></path>
                    </svg>
                </i>
            </div>

            <button type="button"
                    class="of-button of-button__web3 of-button__web3-disconnect"
                    (click)="showModal()">
                <of-web3-avatar [seed]="avatar"></of-web3-avatar>
                <span>{{address}}</span>
                <i class="icon icon__svg">
                    <svg width="16" height="8" viewBox="0 0 16 8" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                        <path d="M1.88 0L8 4.94879L14.12 0L16 1.531L8 8L0 1.531L1.88 0Z"
                              fill="currentColor"></path>
                    </svg>
                </i>
            </button>
        </ng-container>

        <div class="of-button of-button__web3 of-button__web3-network of-button__web3-network--error"
             (click)="showNetworkModal()" *ngIf="networkError">
            <span>Wrong network</span>
        </div>
    </ng-container>

    <of-dialog css="of-dialog--web3" #web3Modal *ngIf="address">
        <ng-template>
            <div class="of-dialog__header mb--0">
                <button type="button"
                        class="of-button of-button__close-modal"
                        [mat-dialog-close]="true">
                    <i class="icon icon__svg">
                        <svg width="12" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9.66857 8L16 14.3314V16H14.3314L8 9.66857L1.66857 16H0V14.3314L6.33143 8L0 1.66857V0H1.66857L8 6.33143L14.3314 0H16V1.66857L9.66857 8Z"
                                  fill="currentColor"/>
                        </svg>
                    </i>
                </button>
            </div>

            <div class="of-dialog__body">

                <div class="of-dialog--web3-data">
                    <of-web3-avatar size="60" [seed]="avatar"></of-web3-avatar>
                    <strong>{{address}}</strong>
                </div>

                <div class="of-buttons__grid">
                    <button [class.is-copied]="copied"
                            [cdkCopyToClipboard]="avatar"
                            (cdkCopyToClipboardCopied)="copied=true"
                            class="of-button of-button-w w--50">
                        <i class="icon icon__svg" *ngIf="!copied">
                            <svg fill="none" height="16" viewBox="0 0 17 16" width="17">
                                <path d="M3.04236 12.3027H4.18396V13.3008C4.18396 14.8525 5.03845 15.7002 6.59705 15.7002H13.6244C15.183 15.7002 16.0375 14.8525 16.0375 13.3008V6.24609C16.0375 4.69434 15.183 3.84668 13.6244 3.84668H12.4828V2.8418C12.4828 1.29688 11.6283 0.442383 10.0697 0.442383H3.04236C1.48376 0.442383 0.629272 1.29004 0.629272 2.8418V9.90332C0.629272 11.4551 1.48376 12.3027 3.04236 12.3027ZM3.23376 10.5391C2.68689 10.5391 2.39294 10.2656 2.39294 9.68457V3.06055C2.39294 2.47949 2.68689 2.21289 3.23376 2.21289H9.8783C10.4252 2.21289 10.7191 2.47949 10.7191 3.06055V3.84668H6.59705C5.03845 3.84668 4.18396 4.69434 4.18396 6.24609V10.5391H3.23376ZM6.78845 13.9365C6.24158 13.9365 5.94763 13.6699 5.94763 13.0889V6.45801C5.94763 5.87695 6.24158 5.61035 6.78845 5.61035H13.433C13.9799 5.61035 14.2738 5.87695 14.2738 6.45801V13.0889C14.2738 13.6699 13.9799 13.9365 13.433 13.9365H6.78845Z"
                                      fill="currentColor"></path>
                            </svg>
                        </i>

                        <i class="icon icon__svg" *ngIf="copied">
                            <svg height="16" viewBox="0 0 16 16" width="16" xmlns="http://www.w3.org/2000/svg">
                                <path fill="currentColor" d="m1 11v-7h2v5h10v2z" fill-rule="evenodd"
                                      transform="matrix(.70710678 -.70710678 .70710678 .70710678 -2.253048 6.146447)"/>
                            </svg>
                        </i>

                        {{copied ? 'Copied' : 'Copy Address'}}
                    </button>

                    <button color="primary"
                            [mat-dialog-close]="true"
                            class="of-button of-button-w w--50" (click)="disconnect()">
                        <i class="icon icon__svg">
                            <svg fill="none" height="16" viewBox="0 0 18 16" width="18">
                                <path d="M2.67834 15.5908H9.99963C11.5514 15.5908 12.399 14.7432 12.399 13.1777V10.2656H10.6354V12.9863C10.6354 13.5332 10.3688 13.8271 9.78772 13.8271H2.89026C2.3092 13.8271 2.0426 13.5332 2.0426 12.9863V3.15625C2.0426 2.60254 2.3092 2.30859 2.89026 2.30859H9.78772C10.3688 2.30859 10.6354 2.60254 10.6354 3.15625V5.89746H12.399V2.95801C12.399 1.39941 11.5514 0.544922 9.99963 0.544922H2.67834C1.12659 0.544922 0.278931 1.39941 0.278931 2.95801V13.1777C0.278931 14.7432 1.12659 15.5908 2.67834 15.5908ZM7.43616 8.85059H14.0875L15.0924 8.78906L14.566 9.14453L13.6842 9.96484C13.5406 10.1016 13.4586 10.2861 13.4586 10.4844C13.4586 10.8398 13.7321 11.168 14.1217 11.168C14.3199 11.168 14.4635 11.0928 14.6002 10.9561L16.7809 8.68652C16.986 8.48145 17.0543 8.27637 17.0543 8.06445C17.0543 7.85254 16.986 7.64746 16.7809 7.43555L14.6002 5.17285C14.4635 5.03613 14.3199 4.9541 14.1217 4.9541C13.7321 4.9541 13.4586 5.27539 13.4586 5.6377C13.4586 5.83594 13.5406 6.02734 13.6842 6.15723L14.566 6.98438L15.0924 7.33984L14.0875 7.27148H7.43616C7.01917 7.27148 6.65686 7.62012 6.65686 8.06445C6.65686 8.50195 7.01917 8.85059 7.43616 8.85059Z"
                                      fill="currentColor"></path>
                            </svg>
                        </i>
                        Disconnect
                    </button>
                </div>
            </div>
        </ng-template>
    </of-dialog>

    <of-dialog css="of-dialog--web3" #web3NetworkModal>
        <ng-template>
            <div class="of-dialog__header mb--0">
                <h3>Switch networks</h3>
                <button type="button"
                        class="of-button of-button__close-modal"
                        [mat-dialog-close]="true">
                    <i class="icon icon__svg">
                        <svg width="12" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9.66857 8L16 14.3314V16H14.3314L8 9.66857L1.66857 16H0V14.3314L6.33143 8L0 1.66857V0H1.66857L8 6.33143L14.3314 0H16V1.66857L9.66857 8Z"
                                  fill="currentColor"/>
                        </svg>
                    </i>
                </button>
            </div>

            <div class="of-dialog__body">
                <div class="of-dialog--web3-networks">
                    <ul>
                        <li *ngFor="let net of networks"
                            [class.is-active]="net.chainName === network"
                            (click)="switchNetwork(net)">
                            <span class="icon {{net.icon}}"></span>
                            <strong>{{net.chainName}}</strong>
                        </li>
                    </ul>
                </div>

                <button color="primary"
                        [mat-dialog-close]="true"
                        class="of-button of-button-w w--100" (click)="disconnect()">
                    <i class="icon icon__svg">
                        <svg fill="none" height="16" viewBox="0 0 18 16" width="18">
                            <path d="M2.67834 15.5908H9.99963C11.5514 15.5908 12.399 14.7432 12.399 13.1777V10.2656H10.6354V12.9863C10.6354 13.5332 10.3688 13.8271 9.78772 13.8271H2.89026C2.3092 13.8271 2.0426 13.5332 2.0426 12.9863V3.15625C2.0426 2.60254 2.3092 2.30859 2.89026 2.30859H9.78772C10.3688 2.30859 10.6354 2.60254 10.6354 3.15625V5.89746H12.399V2.95801C12.399 1.39941 11.5514 0.544922 9.99963 0.544922H2.67834C1.12659 0.544922 0.278931 1.39941 0.278931 2.95801V13.1777C0.278931 14.7432 1.12659 15.5908 2.67834 15.5908ZM7.43616 8.85059H14.0875L15.0924 8.78906L14.566 9.14453L13.6842 9.96484C13.5406 10.1016 13.4586 10.2861 13.4586 10.4844C13.4586 10.8398 13.7321 11.168 14.1217 11.168C14.3199 11.168 14.4635 11.0928 14.6002 10.9561L16.7809 8.68652C16.986 8.48145 17.0543 8.27637 17.0543 8.06445C17.0543 7.85254 16.986 7.64746 16.7809 7.43555L14.6002 5.17285C14.4635 5.03613 14.3199 4.9541 14.1217 4.9541C13.7321 4.9541 13.4586 5.27539 13.4586 5.6377C13.4586 5.83594 13.5406 6.02734 13.6842 6.15723L14.566 6.98438L15.0924 7.33984L14.0875 7.27148H7.43616C7.01917 7.27148 6.65686 7.62012 6.65686 8.06445C6.65686 8.50195 7.01917 8.85059 7.43616 8.85059Z"
                                  fill="currentColor"></path>
                        </svg>
                    </i>
                    Disconnect
                </button>
            </div>
        </ng-template>
    </of-dialog>
</ng-container>
