import {Component, Input, OnInit} from '@angular/core';
import {WebcamImage, WebcamInitError, WebcamUtil} from 'ngx-webcam';
import {Observable, Subject} from 'rxjs';
import {ConceptFormModel, PropertyFormModel} from '../../../../service/ontology-form.service';
import {SocketIoService} from '../../../../service/socket-io.service';


@Component({
    selector: 'of-camera',
    templateUrl: './camera.component.html',
    styleUrls: ['./camera.component.scss'],
    providers: [SocketIoService]
})
export class CameraComponent implements OnInit {

    @Input() model: ConceptFormModel;
    @Input() property: PropertyFormModel;
    @Input() readonly: boolean;
    @Input() imageQuality = 0.5;
    @Input() targetFps = 30;

    public webcamImage: WebcamImage;

    // toggle webcam on/off
    public showWebcam = true;
    public allowCameraSwitch = true;
    public multipleWebcamsAvailable = false;
    public deviceId: string;
    public videoOptions: MediaTrackConstraints = {
        width: {ideal: 1024},
        height: {ideal: 768}
    };
    public errors: WebcamInitError[] = [];

    // webcam snapshot trigger
    private trigger: Subject<void> = new Subject<void>();
    // switch to next / previous / specific webcam; true/false: forward/backwards, string: deviceId
    private nextWebcam: Subject<boolean | string> = new Subject<boolean | string>();

    constructor() {

    }

    ngOnInit(): void {
        WebcamUtil.getAvailableVideoInputs()
            .then((mediaDevices: MediaDeviceInfo[]) => {
                this.multipleWebcamsAvailable = mediaDevices && mediaDevices.length > 1;
            });
        this.processFrame();
    }

    private processFrame() {
        this.triggerSnapshot();
        const timeInMs = 1000 / this.targetFps;
        setTimeout(() => {
            this.processFrame();
        }, timeInMs);
    }


    public triggerSnapshot(): void {
        this.trigger.next();
    }

    public toggleWebcam(): void {
        this.showWebcam = !this.showWebcam;
    }

    public handleInitError(error: WebcamInitError): void {
        this.errors.push(error);
    }

    public showNextWebcam(directionOrDeviceId: boolean | string): void {
        // true => move forward through devices
        // false => move backwards through devices
        // string => move to device with given deviceId
        this.nextWebcam.next(directionOrDeviceId);
    }

    public handleImage(webcamImage: WebcamImage): void {

        this.webcamImage = webcamImage;
        // const compressedFrame = this.compressionService.compress(uncompressedFrame);
        //
        // this.frame = {
        //     webcamImage: webcamImage,
        //     compressedFrame: compressedFrame,
        //     originalFrame: uncompressedFrame,
        //     uncompressedSize: Math.round(uncompressedFrame.length / 1024),
        //     compressedSize: Math.round(compressedFrame.length / 1024),
        // }
    }

    public cameraWasSwitched(deviceId: string): void {
        this.deviceId = deviceId;
    }

    public get triggerObservable(): Observable<void> {
        return this.trigger.asObservable();
    }

    public get nextWebcamObservable(): Observable<boolean | string> {
        return this.nextWebcam.asObservable();
    }

}
