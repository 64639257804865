<div class="of-dialog__header">
    <h4 class="of-dialog__header-title">{{'gallery.create_gallery_title' | translate}}</h4>
    <button (click)="closeModal()"
            style="z-index: 2;"
            type="button"
            class="of-button of-button__close-modal">
        <i class="icon icon__svg">
            <svg width="12" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9.66857 8L16 14.3314V16H14.3314L8 9.66857L1.66857 16H0V14.3314L6.33143 8L0 1.66857V0H1.66857L8 6.33143L14.3314 0H16V1.66857L9.66857 8Z"
                      fill="currentColor"/>
            </svg>
        </i>
    </button>
</div>

<div class="of-dialog__body pt--5">

    <div class="of-dialog__body-inner">

        <form [formGroup]="formGroup">
            <div class="register__content">

                <div fxLayout="column">
                    <mat-form-field appearance="outline">
                        <mat-label>{{'gallery.prompt_used' | translate}}</mat-label>
                        <textarea formControlName="prompt"
                               matInput maxlength="250"
                               rows="5"
                               type="text"></textarea>
                        <mat-error *ngIf="isValid('prompt')">
                            <ng-container *ngFor="let error of getErrors('prompt')">
                                <!-- {{'register.errors.phone.' + error | translate}} -->
                                {{error}}
                            </ng-container>
                        </mat-error>
                        <mat-hint>
                            {{ formGroup.get('prompt')?.value?.length || 0 }}/250
                        </mat-hint>
                    </mat-form-field>
                </div>

                <div fxLayout="column" class="column--upload" fxLayout.gt-md="row"
                     style="width: 100%">
                    <of-upload *ngIf="formGroup"
                               [uploadLabelKey]="'general.upload_file'"
                               [column]="imageModel"
                               [imageCss]="'img'"
                               [figureCss]="'figure'"
                               (errorsFound)="imageErrors($event)"
                               [form]="formGroup"
                               [manualUpload]="beginUpload"
                               (uploadCompleted)="submitForm($event)"
                               ></of-upload>
                </div>

            </div>
        </form>

        <div class="form__error mt--20" *ngIf="error">
            <mat-icon>error</mat-icon>
            <span>{{ error | async }}</span>
        </div>

    </div>

</div>


<div class="of-dialog__footer">

    <button mat-raised-button
            color="primary"
            class="of-button ml--10"
            (click)="notifyUpload()"
            [disabled]="formGroup.invalid || hasImageError || isFormLoading"
            >
        <i class="icon icon__svg">
            <svg height="16" viewBox="0 0 16 16" width="16" xmlns="http://www.w3.org/2000/svg">
                <path fill="currentColor" d="m1 11v-7h2v5h10v2z" fill-rule="evenodd"
                    transform="matrix(.70710678 -.70710678 .70710678 .70710678 -2.253048 6.146447)"/>
            </svg>
        </i>
        Save
        <mat-spinner *ngIf="isFormLoading" style="position: absolute; top: 50%; left: 50%; margin-top: -15px; margin-left: -15px;"
                    diameter="30"></mat-spinner>
        </button>
</div>