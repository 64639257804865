import { Mission } from './../../../../core/model/mission';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'of-dashboard-slider-item',
  templateUrl: './dashboard-slider-item.component.html',
  styleUrls: ['./dashboard-slider-item.component.scss']
})
export class DashboardSliderItemComponent implements OnInit {

  @Input() mission: Mission;

  constructor() { }

  ngOnInit(): void {
  }

}
