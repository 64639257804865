<div class="slider-cover" *ngIf="missions?.length > 0">

  <div class="slider-jumbtron">
    <h2 class="of-section__title">{{'dashboard.welcome' | translate}}</h2>
    <p class="of-section__subtitle">{{'dashboard.join' | translate}}</p>
  </div>

  <div *ngIf="missions.length > 0" class="slider-items">
    <button (click)="slide(-1, true)" type="button" class="swiper__button swiper__button-prev">
      <i style="font-size: 40px;" class="ri-arrow-left-s-line"></i>
    </button>
    <button (click)="slide(1, true)" type="button" class="swiper__button swiper__button-next">
        <i style="font-size: 40px;" class="ri-arrow-right-s-line"></i>
    </button>
    <section id="slider">
      <input type="radio" name="slider" id="s1" [checked]="index === 1">
      <input type="radio" name="slider" id="s2" [checked]="index === 2">
      <input type="radio" name="slider" id="s3" [checked]="index === 3">
      <input type="radio" name="slider" id="s4" [checked]="index === 4">
      <input type="radio" name="slider" id="s5" [checked]="index === 5">
      <label *ngIf="missions[0]" (click)="checked($event, 1, missions[0])" for="s1" id="slide1">
        <of-dashboard-slider-item [mission]="missions[0]" ></of-dashboard-slider-item>
      </label>
      <label *ngIf="missions[1]" (click)="checked($event, 2, missions[1])" for="s2" id="slide2">
        <of-dashboard-slider-item [mission]="missions[1]" ></of-dashboard-slider-item>
      </label>
      <label *ngIf="missions[2]" (click)="checked($event, 3, missions[2])" for="s3" id="slide3">
        <of-dashboard-slider-item [mission]="missions[2]" ></of-dashboard-slider-item>
      </label>
      <label *ngIf="missions[3]" (click)="checked($event, 4, missions[3])" for="s4" id="slide4">
        <of-dashboard-slider-item [mission]="missions[3]" ></of-dashboard-slider-item>
      </label>
      <label *ngIf="missions[4]" (click)="checked($event, 5, missions[4])" for="s5" id="slide5">
        <of-dashboard-slider-item *ngIf="missions[4]" [mission]="missions[4]" ></of-dashboard-slider-item>
      </label>
    </section>
  </div>

</div>
