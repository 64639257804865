

<!-- <div class="masonry">
    <ng-container *ngFor="let item of gallery" >
        <of-gallery-item [item]="item" (selected)="itemSelected($event)" (userSelected)="userSelected($event)" ></of-gallery-item>
    </ng-container>
</div> -->

<!-- <div class="grid-container">
    <div *ngFor="let galler of chuckedItems; let i = index" class="column">
        <ng-container *ngFor="let item of galler" >
            <of-gallery-item [item]="item" (selected)="itemSelected($event)" (userSelected)="userSelected($event)" ></of-gallery-item>
        </ng-container>
    </div>
</div> -->

<div class="list-cover">
    <div class="list-cover-item">
        <div *ngFor="let item of galleryToDisplay">
            <of-gallery-item [item]="item" (selected)="itemSelected($event)" (userSelected)="userSelected($event)" ></of-gallery-item>
        </div>
    </div>
</div>

<ng-template #template>
    <of-single-gallery-page [gallery]="selected"></of-single-gallery-page>
</ng-template>

<ng-template #templateUser>
    <of-profile-page [userIdFromParent]="userId" ></of-profile-page>
</ng-template>

<of-dialog [width]="'900px'" #dialog>
    <ng-template>
        <of-single-gallery-page (close)="closeModal()" [galleryList]="gallery" [gallery]="selected" (userSelected)="userSelected($event)"></of-single-gallery-page>
    </ng-template>
</of-dialog>