import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class NetworkService {
    private networkConfig = [
        {
            chainName: 'BNB Smart Chain',
            icon: 'icon-bnb',
            chainId: '0x38',
            rpcUrls: 'https://bsc-dataseed.binance.org',
            nativeCurrency: {
                name: 'BNB',
                symbol: 'BNB',
                decimals: 18
            },
            blockExplorerUrls: ['https://bscscan.com']
        },
        {
            chainName: 'BNB Smart Chain Testnet',
            icon: 'icon-bnb',
            chainId: '0x61',
            rpcUrls: 'https://data-seed-prebsc-1-s1.binance.org:8545',
            nativeCurrency: {
                name: 'BNB',
                symbol: 'BNB',
                decimals: 18
            },
            blockExplorerUrls: ['https://testnet.bscscan.com']
        },
    ];

    public getNetworks() {
        const chains = environment.chains;
        const networks = environment.production ? [this.networkConfig[0]] : this.networkConfig;

        return networks
            .filter(network => chains.indexOf(network.chainId) !== -1);
    }

    public getDefaultNetwork() {
        const allowedChains = this.getNetworks();
        return allowedChains?.[0];
    }
}
