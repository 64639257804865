<div class="of-thumbs of-thumbnail of-thumbnail--single"
*ngIf="images && images.length === 1">
<img loading="lazy" src="{{images[0]?.image?.url}}"/>
</div>

<div class="of-thumbs of-carousel" *ngIf="images && images.length > 1">
 <swiper [pagination]="true"
         [navigation]="navigation"
         [slidesPerView]="1.2"
         [spaceBetween]="20"
         [breakpoints]="breakpoints"
         class="of-swiper">
     <ng-container *ngFor="let entry of images">
         <ng-template swiperSlide>
             <div class="of-thumbs__item">
                 <img src="{{entry.image?.url}}"/>
             </div>
         </ng-template>
     </ng-container>
 </swiper>

 <button type="button" class="swiper__button swiper__button-prev">
     <i style="font-size: 40px;" class="ri-arrow-left-s-line"></i>
 </button>
 <button type="button" class="swiper__button swiper__button-next">
     <i style="font-size: 40px;" class="ri-arrow-right-s-line"></i>
 </button>
</div>