<i class="icon icon__svg">
    <svg fill="none" height="19" viewBox="0 0 19 19" width="19"
         xmlns="http://www.w3.org/2000/svg">
        <path
                d="M8.35 7.19535H10.15V5.39147H8.35M9.25 17.1167C5.281 17.1167 2.05 13.8787 2.05 9.90117C2.05 5.92361 5.281 2.68565 9.25 2.68565C13.219 2.68565 16.45 5.92361 16.45 9.90117C16.45 13.8787 13.219 17.1167 9.25 17.1167ZM9.25 0.881775C8.0681 0.881775 6.89778 1.11507 5.80585 1.56834C4.71392 2.0216 3.72177 2.68597 2.88604 3.52349C1.19821 5.21496 0.25 7.50907 0.25 9.90117C0.25 12.2933 1.19821 14.5874 2.88604 16.2788C3.72177 17.1164 4.71392 17.7807 5.80585 18.234C6.89778 18.6873 8.0681 18.9206 9.25 18.9206C11.6369 18.9206 13.9261 17.9703 15.614 16.2788C17.3018 14.5874 18.25 12.2933 18.25 9.90117C18.25 8.71672 18.0172 7.54388 17.5649 6.44959C17.1126 5.35531 16.4497 4.36102 15.614 3.52349C14.7782 2.68597 13.7861 2.0216 12.6942 1.56834C11.6022 1.11507 10.4319 0.881775 9.25 0.881775ZM8.35 14.4109H10.15V8.99923H8.35V14.4109Z"
                fill="#2776EA"></path>
    </svg>
</i>
<h4>Sign in to start your mission</h4>
<p>Become a <span>Openfabric</span> promoter and join our community with more than 16k+
    members.</p>
<button type="button"
        mat-raised-button
        class="of-button of-button--sm"
        color="primary"
        (click)="showRegisterModal()">
    Start now
</button>