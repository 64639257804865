import {Component, Input, AfterViewInit, Renderer2, ViewChild, SimpleChanges, OnChanges} from '@angular/core';
import * as jazzicon from '@metamask/jazzicon';

@Component({
    selector: 'of-web3-avatar',
    templateUrl: './web3-avatar.component.html',
    styleUrls: ['./web3-avatar.component.scss']
})
export class Web3AvatarComponent implements AfterViewInit, OnChanges {
    @Input() seed;
    @Input() size = 32;

    @ViewChild('avatarRef') avatarRef;

    constructor(private renderer: Renderer2) {
    }

    ngAfterViewInit(): void {
        this.renderAvatar();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.seed && !changes.seed.firstChange && changes.seed.currentValue) {
            this.renderAvatar();
        }
    }

    private renderAvatar() {
        let seedInteger = this.seed;
        if (!seedInteger) {
            return;
        }

        if (typeof this.seed === 'string') {
            seedInteger = this.jsNumberForAddress(this.seed);
        }

        const value = jazzicon(this.size, seedInteger);

        Array.from(this.avatarRef.nativeElement.children).forEach(child => {
            this.renderer.removeChild(this.avatarRef.nativeElement, child);
        });

        this.renderer.appendChild(this.avatarRef.nativeElement, value);
    }

    private jsNumberForAddress(address) {
        const addr = address.slice(2, 10);
        return parseInt(addr, 16);
    }
}
