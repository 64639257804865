import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {UploadFileComponent} from './components/ontology-form/ontology-input-field/upload-file/upload-file.component';
import {DataImageComponent} from './components/ontology-form/ontology-input-field/data-image/data-image.component';
import {CameraComponent} from './components/ontology-form/ontology-input-field/camera/camera.component';
import {DialogComponent} from './components/dialog/dialog-rating/dialog.component';
import {RatingProgressComponent} from './components/rating-progress/rating-progress.component';
import {OntologyInputFieldGroupComponent} from './components/ontology-form/ontology-input-field-group/ontology-input-field-group.component';
import {OntologyInputFieldComponent} from './components/ontology-form/ontology-input-field/ontology-input-field.component';
import {OntologyInputComponent} from './components/ontology-form/ontology-input.component';
import {OffscreenPanelComponent} from './components/offscreen-panel/offscreen-panel.component';
import {SearchComponent} from './components/search/search.component';
import {ListComponent} from './components/list/list.component';
import {DetailComponent} from './components/detail/detail.component';
import {MainPageComponent} from './components/main-page/main-page.component';
import {DialogConfirmComponent} from './components/dialog/dialog-confirm/dialog-confirm.component';
import {MaterialModule} from '../external/material.module';
import {OptionsPipe} from './pipes/options.pipe';
import {LoggingPipe} from './pipes/logging.pipe';
import {RouterModule} from '@angular/router';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {FlexLayoutModule} from '@angular/flex-layout';
import {SocketIoService} from './service/socket-io.service';
import {OntologyService} from './service/ontology.service';
import {OntologyFormService} from './service/ontology-form.service';
import {LoaderModule} from '../external/loader.module';

import {ContentHeightDirective} from './components/ontology-form/ontology-input-field/content-height.directive';
import {SafeHtmlPipe} from './pipes/url.pipe';
import {MicrophoneComponent} from './components/ontology-form/ontology-input-field/microphone/microphone.component';
import {BlobService} from './service/blob.service';
import {LikeButtonComponent} from './components/like-button/like-button.component';
import {ShareButtonComponent} from './components/share-button/share-button.component';
import {ClipboardModule} from '@angular/cdk/clipboard';
import {FaqComponent} from './components/faq/faq.component';
import {FrontMessageComponent} from './components/front-message/front-message.component';
import {DialogNotifyComponent} from './components/dialog/dialog-notify/dialog-notify.component';
import {SearchItemComponent} from './components/search/search-item/search-item.component';
import {MissionBarComponent} from '../app/pages/mission-page/mission-bar/mission-bar.component';
import {ObserveVisibilityDirective} from './directives/observ-visibility.directive';
import {MissionStepComponent} from '../app/pages/mission-page/mission-step/mission-step.component';
import {
    MissionStartButtonComponent
} from '../app/pages/mission-page/mission-start-button/mission-start-button.component';
import {ListRewardsComponent} from './components/list/list-rewards/list-rewards.component';
import {NgxGoogleAnalyticsModule} from 'ngx-google-analytics';
import {DialogRegisterComponent} from './components/dialog/dialog-register/dialog-register.component';
import {ListItemRewardComponent} from './components/list/list-item-reward/list-item-reward.component';
import {ListItemComponent} from './components/list/list-item/list-item.component';
import {UploadComponent} from './components/upload/upload.component';
import {SwiperModule} from 'swiper/angular';
import {ListItemUserCardComponent} from './components/list/list-item-user-card/list-item-user-card.component';
import {ScrollingModule} from '@angular/cdk/scrolling';
import {InfiniteListWithNoCdkComponent} from './components/list/infinite-list-with-no-cdk/infinite-list-with-no-cdk.component';
import {UserBadgesComponent} from '../app/pages/profile-page/user-badges/user-badges.component';
import {UserBadgeItemComponent} from '../app/pages/profile-page/user-badge-item/user-badge-item.component';
import {UserMissionsComponent} from '../app/pages/profile-page/user-missions/user-missions.component';
import {ListUserMissionComponent} from './components/list/list-user-mission/list-user-mission.component';
import {ContentLoaderComponent} from './components/content-loader/content-loader.component';
import {SvgsComponent} from './components/svgs/svgs.component';
import {SignInOrRegisterComponent} from './components/sign-in-or-register/sign-in-or-register.component';
import {UnlockingStatsComponent} from './components/unlocking-stats/unlocking-stats.component';
import {LineHeaderComponent} from './components/line-header/line-header.component';
import {ImageSliderComponent} from './components/image-slider/image-slider.component';
import {MissionCompletedByUsersComponent} from './components/mission-completed-by-users/mission-completed-by-users.component';
import {ClickOutsideDirective} from './directives/click-outside.directive';
import {CreateNftComponent} from './components/nft/create-nft/create-nft.component';
import {ListNftComponent} from './components/nft/list-nft/list-nft.component';
import {ItemNftComponent} from './components/nft/list-nft/item/item-nft.component';
import {Web3ConnectButtonComponent} from './components/web3-connect-button/web3-connect-button.component';
import {Web3AvatarComponent} from './components/web3-avatar/web3-avatar.component';
import {PromptExamplesComponent} from './components/nft/prompt-examples/prompt-examples.component';
import {MoreInfoPanelComponent} from './components/more-info-panel/more-info-panel.component';
import {TrimWordPipe} from './pipes/trim-word.pipe';
import {HumanTimePipe} from './pipes/human-time.pipe';
import {HumanNumberPipe} from './pipes/human-number.pipe';
import {MintFabricatorNftComponent} from './components/nft/mint-fabricator-nft/mint-fabricator-nft.component';


@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        ReactiveFormsModule,
        MaterialModule,
        FlexLayoutModule,
        // --
        LoaderModule,
        FormsModule,
        ClipboardModule,
        NgxGoogleAnalyticsModule,
        SwiperModule,
        ScrollingModule,
    ],
    exports: [
        LoggingPipe,
        ListComponent,
        DetailComponent,
        DialogComponent,
        DialogRegisterComponent,
        MainPageComponent,
        ContentHeightDirective,
        OntologyInputComponent,
        RatingProgressComponent,
        LikeButtonComponent,
        ShareButtonComponent,
        FaqComponent,
        FrontMessageComponent,
        SearchComponent,
        SearchItemComponent,
        MissionBarComponent,
        ObserveVisibilityDirective,
        MissionStepComponent,
        MissionStartButtonComponent,
        ListRewardsComponent,
        ListItemRewardComponent,
        ListItemComponent,
        DataImageComponent,
        UploadFileComponent,
        UploadComponent,
        SignInOrRegisterComponent,
        UnlockingStatsComponent,

        ListItemUserCardComponent,
        InfiniteListWithNoCdkComponent,
        UserBadgesComponent,
        UserBadgeItemComponent,
        UserMissionsComponent,
        ListUserMissionComponent,
        ContentLoaderComponent,
        SvgsComponent,
        LineHeaderComponent,
        ImageSliderComponent,
        MissionCompletedByUsersComponent,
        ImageSliderComponent,
        MissionCompletedByUsersComponent,
        ClickOutsideDirective,

        CreateNftComponent,
        ListNftComponent,
        ItemNftComponent,
        Web3ConnectButtonComponent,
        Web3AvatarComponent,
        PromptExamplesComponent,
        OffscreenPanelComponent,
        MoreInfoPanelComponent,
        TrimWordPipe,
        HumanTimePipe,
        HumanNumberPipe,
        MintFabricatorNftComponent
    ],
    declarations: [
        SearchComponent,
        SearchItemComponent,
        ListComponent,
        DetailComponent,
        MainPageComponent,
        DialogConfirmComponent,
        DialogComponent,
        DialogRegisterComponent,
        OffscreenPanelComponent,
        OntologyInputComponent,
        OntologyInputFieldGroupComponent,
        OntologyInputFieldComponent,
        CameraComponent,
        RatingProgressComponent,
        UploadFileComponent,
        DataImageComponent,
        OptionsPipe,
        LoggingPipe,
        SafeHtmlPipe,
        ContentHeightDirective,
        MicrophoneComponent,
        LikeButtonComponent,
        ShareButtonComponent,
        FaqComponent,
        FrontMessageComponent,
        DialogNotifyComponent,
        MissionBarComponent,
        ObserveVisibilityDirective,
        MissionStepComponent,
        MissionStartButtonComponent,
        ListRewardsComponent,
        ListItemRewardComponent,
        ListItemComponent,
        UploadComponent,
        SignInOrRegisterComponent,
        UnlockingStatsComponent,

        ListItemUserCardComponent,
        InfiniteListWithNoCdkComponent,
        UserBadgesComponent,
        UserBadgeItemComponent,
        UserMissionsComponent,
        ListUserMissionComponent,
        ContentLoaderComponent,
        SvgsComponent,
        LineHeaderComponent,
        ImageSliderComponent,
        MissionCompletedByUsersComponent,
        ImageSliderComponent,
        MissionCompletedByUsersComponent,
        ClickOutsideDirective,

        CreateNftComponent,
        ListNftComponent,
        ItemNftComponent,
        Web3ConnectButtonComponent,
        Web3AvatarComponent,
        PromptExamplesComponent,
        MoreInfoPanelComponent,
        TrimWordPipe,
        HumanTimePipe,
        HumanNumberPipe,
        MintFabricatorNftComponent
    ],
    entryComponents: [DialogConfirmComponent, DialogNotifyComponent, DialogRegisterComponent],
    providers: [
        SocketIoService,
        OntologyService,
        OntologyFormService,
        BlobService,
    ],

})
export class CoreModule {
}
