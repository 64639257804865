import { MobileNavigationMenuComponent } from './mobile-navigation-menu/mobile-navigation-menu.component';
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {HeaderComponent} from './header/header.component';
import {NavigationComponent} from './navigation/navigation.component';
import {FooterComponent} from './footer/footer.component';
import {SidebarComponent} from './sidebar/sidebar.component';
import {RouterModule} from '@angular/router';
import {MaterialModule} from '../../external/material.module';
import {AppLayoutComponent} from './application/app-layout.component';
import {FlexLayoutModule} from '@angular/flex-layout';
import {LoaderModule} from '../../external/loader.module';
import { GlobalSearchComponent } from './global-search/global-search.component';
import { QuickLinksComponent } from './quick-links/quick-links.component';
import { CoreModule } from '../../core/core.module';
import { CopyrightComponent } from './copyright/copyright.component';
import { LogoComponent } from './logo/logo.component';
import { ReactiveFormsModule } from '@angular/forms';
import { UserCardComponent } from './user-card/user-card.component';
import {NotificationLoadingComponent} from './notification-loading/notification-loading.component';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        MaterialModule,
        FlexLayoutModule,
        // --
        LoaderModule,
        CoreModule,
        ReactiveFormsModule,
    ],
    exports: [
        HeaderComponent,
        FooterComponent,
        QuickLinksComponent,
        CopyrightComponent,
        NotificationLoadingComponent
    ],
    declarations: [
        HeaderComponent,
        NavigationComponent,
        FooterComponent,
        SidebarComponent,
        AppLayoutComponent,
        GlobalSearchComponent,
        QuickLinksComponent,
        CopyrightComponent,
        UserCardComponent,
        LogoComponent,
        MobileNavigationMenuComponent,
        NotificationLoadingComponent
    ]

})
export class AppLayoutModule {
}
