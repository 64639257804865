import { MoreInfoEventArg, MoreInfoEventType } from './../../../../core/service/events/more-info.service';
import { ServiceBusEvent } from './../../../../core/service/events/service-bus.event';
import { UserRewardStat } from './../../../../core/model/user-reward-stat';
import { User } from './../../../../core/model/user';
import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'of-leaderboard-list',
  templateUrl: './leaderboard-list.component.html',
  styleUrls: ['./leaderboard-list.component.scss']
})
export class LeaderboardListComponent implements OnInit, OnChanges, OnDestroy {

  @Input() public items: UserRewardStat[] = [];
  @Input() public allTime: boolean = true;

  @ViewChild('template') template: TemplateRef<any>;

  users: UserRewardStat[] = []

  public index = 0;
  top3elements = [];
  subscriptions: Subscription[] = [];

  showTop3 = false;

  selectedId;

  constructor(
    private serviceBusEvent: ServiceBusEvent
  ) { }

  ngOnInit(): void {
    this.listenForMoreInfo();
  }


  ngOnChanges(changes: SimpleChanges): void {
    this.checkForTop3();
  }

  checkForTop3(): void {
    this.users = [...this.items];
    this.showTop3 = this.users.length > 3;

    if (this.showTop3) {
      this.top3elements = this.users.splice(0, 3);
      this.index = 3;
    } else {
      this.index = 0;
    }
  }

  userSelected(itemSelected: UserRewardStat): void {
    this.selectedId = itemSelected.user.id;
    this.serviceBusEvent.next(new MoreInfoEventArg(MoreInfoEventType.VIEW, 'VIEW_TEMPLATE', this.template));
  }

  private closePanel(): void {
    const id = this.selectedId;
    this.selectedId = null;
    const element = document.getElementById('user' + id);
    element.scrollIntoView();
  }

  listenForMoreInfo(): void {
    const sub = this.serviceBusEvent.subscribe({
      next: (e: MoreInfoEventArg) => {
        if (e.getSubType() === MoreInfoEventType.CLOSE) {
          this.closePanel();
        }
      },
      error: (error) => {
        console.log(error);
      }
    });
    this.subscriptions.push(sub);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(x => x.unsubscribe());
  }
}
