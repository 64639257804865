import { Attribute, BelongsTo, HasMany, JsonApiModel, JsonApiModelConfig } from "angular2-jsonapi";
import { TimestampConverter } from "../logic/timestamp-converter";
import { Field, ValueListConfig } from "../utils/field-config";
import { User } from "./user";
import { Image } from "./image";
import { GalleryLike } from "./galleryLike";

export enum GalleryStatus {
    APPROVED = 'APPROVED',
    PENDING = 'PENDING',
    DENIED = 'DENIED'
}

@JsonApiModelConfig({type: 'galleryImage'})
export class Gallery extends JsonApiModel {
    
    @Attribute()
    id: string;

    @Attribute()
    @Field({
        translateKey: 'Author',
        fieldClass: 'form__field--full'
    })
    public authorId: string;

    @Attribute()
    @Field({
        translateKey: 'Prompt',
        fieldClass: 'form__field--full'
    })
    public prompt: string;

    @Attribute()
    @Field({
        translateKey: 'Image',
        fieldClass: 'form__field--full'
    })
    public imageId: string;

    @Attribute()
    @Field({
        translateKey: 'Status',
        required: true,
        valueListConfig: new ValueListConfig(undefined, undefined, undefined, [
            GalleryStatus.APPROVED,
            GalleryStatus.PENDING,
            GalleryStatus.DENIED
        ], undefined),
        fieldClass: 'form__field--full'
    })
    public status: GalleryStatus.APPROVED | GalleryStatus.PENDING | GalleryStatus.DENIED;

    @Attribute()
    @Field({
        translateKey: 'Total Likes',
        fieldClass: 'form__field--full'
    })
    public likesCount: number;

    @Attribute()
    @Field({
        translateKey: 'Dislikes',
        fieldClass: 'form__field--full'
    })
    public dislikeCount: number;

    @Attribute()
    @Field({
        translateKey: 'Views',
        fieldClass: 'form__field--full'
    })
    public viewsCount: number;

    @BelongsTo()
    public author: User;

    @BelongsTo()
    public image: Image;

    @HasMany()
    public likes: GalleryLike[];
    
    @Attribute({converter: new TimestampConverter()})
    deletedAt: Date;

    @Attribute({converter: new TimestampConverter()})
    createdAt: Date;

    @Attribute({converter: new TimestampConverter()})
    updatedAt: Date;
}