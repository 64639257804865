import {Injectable, RendererFactory2} from '@angular/core';

enum DOC_ORIENTATION {
    Up = 1,
    UpMirrored = 2,
    Down = 3,
    DownMirrored = 4,
    LeftMirrored = 5,
    Right = 6,
    RightMirrored = 7,
    Left = 8,
    Default = 0,
    NotJpeg = -1,
    NotDefined = -2,
}

@Injectable({
    providedIn: 'root'
})
export class CompressService {
    private render: any;

    constructor(private readonly rendererFactory: RendererFactory2) {
        this.render = this.rendererFactory.createRenderer(null, null);
    }

    private compress(imageDataUrlSource, orientation, ratio = 50, quality = 50, maxwidth = 0, maxheight = 0): Promise<string> {
        const render = this.render;

        return new Promise(function (resolve, reject) {
            quality = quality / 100;
            ratio = ratio / 100;
            const sourceImage = new Image();

            // important for safari: we need to wait for onload event
            sourceImage.onload = () => {
                const canvas: HTMLCanvasElement = render.createElement('canvas');
                const ctx: CanvasRenderingContext2D | null = canvas.getContext('2d');

                if (!ctx) {
                    return reject(`No canvas context available`);
                }

                let w = sourceImage.naturalWidth;
                let h = sourceImage.naturalHeight;

                if (!CSS.supports('image-orientation', 'from-image')) {
                    if (orientation === DOC_ORIENTATION.Right || orientation === DOC_ORIENTATION.Left) {
                        const t = w;
                        w = h;
                        h = t;
                    }
                }

                const xratio = maxwidth ? maxwidth / w : 1;
                const yratio = maxheight ? maxheight / h : 1;
                ratio = Math.min(ratio, xratio, yratio);
                canvas.width = w * ratio;
                canvas.height = h * ratio;

                const TO_RADIANS = Math.PI / 180;

                if (CSS.supports('image-orientation', 'from-image') || orientation === DOC_ORIENTATION.Up) {
                    ctx.drawImage(sourceImage, 0, 0, canvas.width, canvas.height);
                } else if (orientation === DOC_ORIENTATION.Right) {
                    ctx.save();
                    ctx.rotate(90 * TO_RADIANS);
                    ctx.translate(0, -canvas.width);
                    ctx.drawImage(sourceImage, 0, 0, canvas.height, canvas.width);
                    ctx.restore();
                } else if (orientation === DOC_ORIENTATION.Left) {
                    ctx.save();
                    ctx.rotate(-90 * TO_RADIANS);
                    ctx.translate(-canvas.width, 0);
                    ctx.drawImage(sourceImage, 0, 0, canvas.height, canvas.width);
                    ctx.restore();
                } else if (orientation === DOC_ORIENTATION.Down) {
                    ctx.save();
                    ctx.rotate(180 * TO_RADIANS);
                    ctx.translate(-canvas.width, -canvas.height);
                    ctx.drawImage(sourceImage, 0, 0, canvas.width, canvas.height);
                    ctx.restore();
                } else {
                    // no orientation value found - same as default UP
                    ctx.drawImage(sourceImage, 0, 0, canvas.width, canvas.height);
                }

                const mime = imageDataUrlSource.substr(5, imageDataUrlSource.split(';')[0].length - 5);
                const result = canvas.toDataURL(mime, quality);

                resolve(result);
            };

            sourceImage.onerror = e => reject(e);
            sourceImage.src = imageDataUrlSource;
        });
    }

    public compressFile(image, orientation, ratio = 50, quality = 50, maxwidth = 0, maxheight = 0): Promise<string> {
        // @ts-ignore
        return this.compress(image, orientation, ratio, quality, maxwidth, maxheight);
    }
}
