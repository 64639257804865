<of-content-loader>
    <div class="loader-content">
      <section class="profile-loader">
        <div class="level-loader"></div>
        <div class="circle-loader"></div>
        <section class="reward-info">
          <div class="name-loader"></div>
          <div class="reward-loader mobile"></div>
        </section>
      </section>
      <div class="reward-section desktop">
        <div class="reward-loader"></div>
      </div>
    </div>
  </of-content-loader>