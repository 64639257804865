import { MissionService } from './../../../../core/service/crud/impl/mission.service';
import { Component, Input, OnInit } from '@angular/core';
import { FilterOperator, SearchCriteria } from '../../../../core/service/crud/search.service';
import { ListComponentService } from '../../../../core/service/events/list-component.service';
import { DetailComponentService } from '../../../../core/service/events/detail-component.service';
import { ContextualTranslationService } from '../../../../core/service/translation/contextual-translation.service';
import { MissionEngagement } from '../../../../core/model/mission-engagement';
import { KeyValue } from '../../../../core/model/ontology/model';
import { MissionAssignmentService } from '../../../../core/service/crud/impl/mission-assignment.service';
import { MissionCompletionStat } from '../../../../core/model/mission-completion-stat';
import { MissionCompletionStatService } from '../../../../core/service/crud/impl/mission-completion-stat.service';

@Component({
  selector: 'of-user-missions',
  templateUrl: './user-missions.component.html',
  styleUrls: ['./user-missions.component.scss'],
  providers: [
    ListComponentService,
    DetailComponentService,
    ContextualTranslationService,
    MissionAssignmentService,
    MissionCompletionStatService,
    MissionService
  ],
})
export class UserMissionsComponent implements OnInit {

  @Input() userId: string;
  public selectionElement: MissionCompletionStat;
  public prototype: MissionCompletionStat;
  public mainListSearchContributor: (criteria: SearchCriteria) => void;
  public postProcessData: (elements: MissionCompletionStat[]) => any;
  public missionEngagements: KeyValue<MissionEngagement> = {};

  public missionState = 'all';
  
  
  constructor(
    public service: MissionCompletionStatService,
    public listComponentService: ListComponentService<MissionCompletionStat>,
    public detailComponentService: DetailComponentService<MissionCompletionStat>,
    public translationService: ContextualTranslationService<MissionCompletionStat>,
    protected missionService: MissionService
  ) { 
    this.prototype = MissionCompletionStat.prototype;

    this.listComponentService.selectElement.subscribe(this.getOnSelectElementSubscriber().bind(this));
    this.detailComponentService.onClose.subscribe(this.getOnCloseDetailPanelSubscriber().bind(this));
    this.detailComponentService.onDelete.subscribe(this.getDeleteSubscriber().bind(this));

    this.initializeSettings();

  }

  private initializeSettings(): void {

    const hideHiddenMission = !this.missionService.canViewHiddenMission();

    // Set search criteria
    this.mainListSearchContributor = (criteria: SearchCriteria) => {
      criteria.clearFilters();
      criteria.include = 'mission';

      criteria.addFilter('missionCompletionStat.userId', FilterOperator.EQUALS, this.userId);

      if (hideHiddenMission) {
        criteria.addFilter('missionCompletionStat.mission.hidden', FilterOperator.EQUALS, false);
      }

      if (this.missionState === 'all') {
        return;
      }

      criteria.addFilter('missionCompletionStat.completed', FilterOperator.EQUALS, this.missionState);
    };

    // Set preprocessing elements before displaying them
    this.postProcessData = (elements: MissionCompletionStat[]) => {
      elements = elements.filter(x => x.mission);
      return elements;
    }
  }

  async ngOnInit(): Promise<void> {
  }

  public getOnCloseDetailPanelSubscriber() {
    return (selection: MissionCompletionStat) => {
        this.selectionElement = undefined;
    }
  }

  public getOnSelectElementSubscriber() {
      return (selection: MissionCompletionStat) => {
          this.selectionElement = selection;
      }
  }

  private getDeleteSubscriber() {
    return (selection: MissionCompletionStat) => {
        selection.deletedAt = new Date();
    }
  }
}
