import {Injectable} from '@angular/core';

interface MetaMaskEthereumProvider {
    provider: Boolean;
    isMetaMask?: boolean;

    once(eventName: string | symbol, listener: (...args: any[]) => void): this;

    on(eventName: string | symbol, listener: (...args: any[]) => void): this;

    off(eventName: string | symbol, listener: (...args: any[]) => void): this;

    addListener(eventName: string | symbol, listener: (...args: any[]) => void): this;

    removeListener(eventName: string | symbol, listener: (...args: any[]) => void): this;

    removeAllListeners(event?: string | symbol): this;
}

interface Window {
    ethereum?: MetaMaskEthereumProvider;
}

@Injectable({
    providedIn: 'root'
})
export class EthersProviderService {
    constructor() {
    }

    detectEthereumProvider<T = MetaMaskEthereumProvider>({mustBeMetaMask = false, silent = false, timeout = 3000} = {}): Promise<T | null> {
        let handled = false;

        return new Promise((resolve) => {
            if ((window as Window).ethereum) {

                handleEthereum();

            } else {

                window.addEventListener(
                    'ethereum#initialized',
                    handleEthereum,
                    {once: true},
                );

                setTimeout(() => {
                    handleEthereum();
                }, timeout);
            }

            function handleEthereum() {

                if (handled) {
                    return;
                }
                handled = true;

                window.removeEventListener('ethereum#initialized', handleEthereum);

                const {ethereum} = window as Window;

                if (ethereum && (!mustBeMetaMask || ethereum.isMetaMask)) {
                    resolve(ethereum as unknown as T);
                } else {
                    resolve(null);
                }
            }
        });
    }
}
