import { Component } from '@angular/core';

@Component({
    selector: 'of-quick-links',
    templateUrl: './quick-links.component.html',
    styleUrls: ['./quick-links.component.scss']
})
export class QuickLinksComponent {
    constructor() {
    }
}
