
<section class="menu-body">    
    <div *ngIf="!user" class="menu-item">
        <span class="link-menu"
        (click)="login(); closeMenu()"
        >
        <i class="icon icon__svg">
            <i class="ri-login-box-line" style="position: relative; top: 10px;"></i>
        </i>
        <span class="of-navigation__menu-item--label">Sign In</span>
        </span>
    </div>
    <div *ngIf="permission.can('VIEW_MISSION')" class="menu-item" [routerLinkActiveOptions]="{exact: true}" routerLinkActive="is-active">
        <a href="" [routerLinkActiveOptions]="{exact: true}"
            class="link-menu"
            routerLink=""
            (click)="closeMenu()"
            routerLinkActive="is-active">
            <i class="icon icon__svg">
                <of-svgs name="dashboard" width="17px" ></of-svgs>
            </i>
            <span class="of-navigation__menu-item--label" *ngIf="!isMobile">Dashboard</span>
            <span class="of-navigation__menu-item--label" *ngIf="isMobile">Home</span>
        </a>
    </div>
    <div *ngIf="permission.can('VIEW_MISSION')" class="menu-item" [routerLinkActiveOptions]="{exact: true}" routerLinkActive="is-active">
        <a href="" [routerLinkActiveOptions]="{exact: true}"
            class="link-menu"
            routerLinkActive="is-active"
            (click)="closeMenu()"
            routerLink="/missions"
        >
            <i class="icon icon__svg">
                <of-svgs name="missions" width="17px"></of-svgs>
            </i>
            <span class="of-navigation__menu-item--label">Missions</span>
        </a>
    </div>
    <div *ngIf="permission.can('VIEW_LEADERBOARD')" class="menu-item" [routerLinkActiveOptions]="{exact: true}" routerLinkActive="is-active">
        <a href="" [routerLinkActiveOptions]="{exact: true}"
            class="link-menu"
            routerLinkActive="is-active"
            (click)="closeMenu()"
            routerLink="/leaderboard"
        >
            <i class="icon icon__svg">
                <of-svgs name="leaderboard" width="17px" fill="none"></of-svgs>
            </i>

            <span class="of-navigation__menu-item--label">Leaderboard</span>
        </a>
    </div>
    <div class="menu-item" *ngIf="permission.can('VIEW_NFT')">
        <a class="link-menu"
           routerLinkActive="is-active"
           (click)="closeMenu()"
           routerLink="/nfts">
            <i class="icon icon__svg">
                <mat-icon>image</mat-icon>
            </i>
            <span class="of-navigation__menu-item--label">AI NFTS</span>
        </a>
    </div>
    <div *ngIf="permission.can('VIEW_GALLERY')" class="menu-item" [routerLinkActiveOptions]="{exact: true}" routerLinkActive="is-active">
        <a href="" [routerLinkActiveOptions]="{exact: true}"
            class="link-menu"
            routerLinkActive="is-active"
            (click)="closeMenu()"
            routerLink="/gallery"
        >
            <i class="icon icon__svg">
                <i style="position: absolute; margin-top: 9px;"  class="ri-gallery-fill"></i>
            </i>

            <span class="of-navigation__menu-item--label">Gallery</span>
        </a>
    </div>
    <div *ngIf="permission.can('VIEW_MISSION')" class="menu-item" [routerLinkActiveOptions]="{exact: true}" routerLinkActive="is-active">
        <a href="" [routerLinkActiveOptions]="{exact: true}"
            class="link-menu"
            routerLinkActive="is-active"
            (click)="closeMenu()"
            routerLink="/faq"
        >
            <i class="icon icon__svg">
                <of-svgs name="faqs" width="17px"></of-svgs>
            </i>

            <span class="of-navigation__menu-item--label">FAQs</span>
        </a>
    </div>
    <div *ngIf="permission.can('VIEW_PROFILE')" class="menu-item" [routerLinkActiveOptions]="{exact: true}" routerLinkActive="is-active">
        <a href="" [routerLinkActiveOptions]="{exact: true}"
            class="link-menu"
            routerLinkActive="is-active"
            (click)="closeMenu()"
            [routerLink]="'/profile/' + user?.id"
        >
            <i class="icon icon__svg">
                <of-svgs name="profile" width="17px"></of-svgs>
            </i>
            <span class="of-navigation__menu-item--label">Profile</span>
        </a>
    </div>
    <div *ngIf="permission.can('VIEW_USER')" class="menu-item" [routerLinkActiveOptions]="{exact: true}" routerLinkActive="is-active">
        <a href="" [routerLinkActiveOptions]="{exact: true}"
            class="link-menu"
            routerLinkActive="is-active"
            (click)="closeMenu()"
            [routerLink]="'/users'"
        >
            <i class="icon icon__svg">
                <of-svgs name="users" width="17px"></of-svgs>
            </i>
            <span class="of-navigation__menu-item--label">Users</span>
        </a>
    </div>
    <div *ngIf="permission.can('VIEW_STATS')" class="menu-item" [routerLinkActiveOptions]="{exact: true}" routerLinkActive="is-active">
        <a href="" [routerLinkActiveOptions]="{exact: true}"
            class="link-menu"
            routerLinkActive="is-active"
            (click)="closeMenu()"
            [routerLink]="'/statistics'"
        >
            <i class="icon icon__svg">
                <mat-icon>query_stats</mat-icon>
            </i>
            <span class="of-navigation__menu-item--label">Statistics</span>
        </a>
    </div>
    <div *ngIf="permission.can('VIEW_TOOLS')" class="menu-item" [routerLinkActiveOptions]="{exact: true}" routerLinkActive="is-active">
        <a href="" [routerLinkActiveOptions]="{exact: true}"
            class="link-menu"
            routerLinkActive="is-active"
            (click)="closeMenu()"
            [routerLink]="'/tools'"
        >
            <i class="icon icon__svg">
                <mat-icon>build</mat-icon>
            </i>
            <span class="of-navigation__menu-item--label">Tools</span>
        </a>
    </div>
    <div *ngIf="permission.can('VERIFY_MISSION_STEP')" class="menu-item" [routerLinkActiveOptions]="{exact: true}" routerLinkActive="is-active">
        <a href="" [routerLinkActiveOptions]="{exact: true}"
            class="link-menu"
            routerLinkActive="is-active"
            (click)="closeMenu()"
            [routerLink]="'/missions-validation'"
        >
            <i class="icon icon__svg">
                <of-svgs name="validation" width="17px"></of-svgs>
            </i>
            <span class="of-navigation__menu-item--label">Validation</span>
        </a>
    </div>
    <div *ngIf="permission.can('VALIDATE_REDEEM_REWARD')" class="menu-item" [routerLinkActiveOptions]="{exact: true}" routerLinkActive="is-active">
        <a href="" [routerLinkActiveOptions]="{exact: true}"
            class="link-menu"
            routerLinkActive="is-active"
            (click)="closeMenu()"
            [routerLink]="'/rewards-validation'"
        >
            <i class="icon icon__svg">
                <of-svgs name="validate_rewards" width="17px"></of-svgs>
            </i>
            <span class="of-navigation__menu-item--label">Validate Rewards</span>
        </a>
    </div>
    <div *ngIf="permission.can('VALIDATE_PROMPT')" class="menu-item" [routerLinkActiveOptions]="{exact: true}" routerLinkActive="is-active">
        <a href="" [routerLinkActiveOptions]="{exact: true}"
            class="link-menu"
            routerLinkActive="is-active"
            (click)="closeMenu()"
            [routerLink]="'/prompt-validation'"
        >
            <i class="icon icon__svg">
                <i style="position: absolute; margin-top: 9px;" class="ri-terminal-box-line"></i>
            </i>
            <span class="of-navigation__menu-item--label">Validate Gallery</span>
        </a>
    </div>
    <div *ngIf="permission.can('VIEW_TRACK')" class="menu-item" [routerLinkActiveOptions]="{exact: true}" routerLinkActive="is-active">
        <a href="" [routerLinkActiveOptions]="{exact: true}"
            class="link-menu"
            routerLinkActive="is-active"
            (click)="closeMenu()"
            [routerLink]="'/tracking'"
        >
            <i class="icon icon__svg">
                <i style="font-size: 19px; position: relative; top: 10px;" class="ri-focus-3-line"></i>
            </i>
            <span class="of-navigation__menu-item--label">Tracking</span>
        </a>
    </div>
    <div *ngIf="permission.can('VIEW_EMAILS')" class="menu-item" [routerLinkActiveOptions]="{exact: true}" routerLinkActive="is-active">
        <a href="" [routerLinkActiveOptions]="{exact: true}"
            class="link-menu"
            routerLinkActive="is-active"
            (click)="closeMenu()"
            [routerLink]="'/emails'"
        >
            <i class="icon icon__svg">
                <of-svgs name="emails" width="17px"></of-svgs>
            </i>
            <span class="of-navigation__menu-item--label">Emails</span>
        </a>
    </div>
    <div *ngIf="permission.can('VIEW_REDEEM')" class="menu-item" [routerLinkActiveOptions]="{exact: true}" routerLinkActive="is-active">
        <a href="" [routerLinkActiveOptions]="{exact: true}"
            class="link-menu"
            routerLinkActive="is-active"
            (click)="closeMenu()"
            [routerLink]="'/redeem-gems'"
        >
            <i class="icon icon__svg">
                <mat-icon>payments</mat-icon>
            </i>
            <span class="of-navigation__menu-item--label">Redeem Gems</span>
        </a>
    </div>
</section>
