import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'of-line-header',
  templateUrl: './line-header.component.html',
  styleUrls: ['./line-header.component.scss']
})
export class LineHeaderComponent implements OnInit {

  @Input() header: string;

  constructor() { }

  ngOnInit(): void {
  }

}
