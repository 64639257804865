import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'of-list-user-mission',
  templateUrl: './list-user-mission.component.html',
  styleUrls: ['./list-user-mission.component.scss'],
  providers: [
  ]
})
export class ListUserMissionComponent implements OnInit {

  @Input() public item: any;
  public missionCompletion: any;

  public mission: any;
  constructor(
  ) {
  }

  async ngOnInit() {
    this.mission = this.item.mission;
    this.missionCompletion = this.item;
  }

}
