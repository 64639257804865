export const en = {
    'mission_status': {
        'NOT_STARTED': 'Start here',
        'PENDING': 'Under review',
        'FAILED': 'Failed',
        'COMPLETED': 'Done',
    },
    'task': {
        'about': 'About',
        'name': 'Name',
        'overview': 'Short description',
        'description': 'Description',
        'reward': 'Reward (א)',
        'edit': 'Edit',
    },
    'detail': {
        'update': 'Update',
        'save': 'Save',
        'close': 'Close',
        'delete': 'Delete',
        'tab': {
            'general': 'General'
        },
        'edit': 'Edit',
        'submit': 'Submit',
        'cancel': 'Cancel',
        'back': 'Back',
        'see_details': 'See Details',
        'claim': 'Claim reward',
    },
    'general': {
        'action': 'Action',
        'reward': 'Reward',
        'available': 'Available',
        'earned': 'Earned',
        'total': 'Total',
        'copyright': '© Copyright {{year}} Openfabric',
        'create_tooltip': 'Create new entry',
        'detail_title_edit': '<span>{{title}} &nbsp;/</span>&nbsp; Edit ',
        'detail_title_create': 'Add',
        'no_data': ' No data available',
        'delete_modal': {
            'title': 'Are you sure you want to proceed with the deletion?',
            'ok': 'Yes',
            'cancel': 'No'
        },
        'error_modal': {
            'title': 'Operation has failed, try again later',
            'ok': 'Ok'
        },
        'search_placeholder': 'Type to search ...',
        'upload_images': 'Upload images',
        'upload_file': 'Drag & drop your screenshot proof...',
        'upload_profile_image': 'Drag & drop your profile image here...',
        'upload_button': 'Browse Files',
        'tab_running': 'Running',
        'tab_drafts': 'Drafts',
        'tab_archived': 'Archived',
        'front_title': 'Openfabric Community',
        'front_subtitle': 'Help Openfabric grow. Get exclusive rewards and opportunities. Become a promoter of the Openfabric Community.',
        'gems_error': 'Oops! It seems like you don\'t have enough gems to mint this NFT right now. Please accumulate more gems and try again!',
        'nft_error': 'The PROMPT is required!',
        'profanity_error': 'Sorry, we can\'t process this text due to profanity or inappropriate content. Please remove any such language and try again!'
    },
    'image': {
        'url': 'Image URL'
    },
    'nft': {
        'errors': {
            'prompt': {
                'required': 'The PROMPT field is required'
            },
            'name': {
                'required': 'The Name field is required'
            },
            'description': {
                'required': 'The Description field is required'
            },
        }
    },
    'login': {
        'expiration_time': 'Remaining <strong>{{remaining}}</strong> minutes',
        'username': 'Email',
        'password': 'Password',
        'create_account': 'Create account',
        'title': 'Login',
        'login': 'Login',
        'no_account': 'Don\'t have an account?',
        'forgot_password': 'Forgot password?',
        'register': 'Register',
        'errors': {
            'invalid_credentials': 'Invalid credentials',
            'password': {
                'required': 'The password is required'
            },
            'user': {
                'required': 'The user is required'
            },
        }
    },
    'register': {
        'have_account': 'Already have an account?',
        'create_account': 'Create account',
        'phone': 'Phone number',
        'first_name': 'First name',
        'last_name': 'Last name',
        'email': 'Email',
        'password': 'Password',
        'password_confirm': 'Password confirm',
        'agree': 'I agree <a href=\'https://openfabric.ai/terms-of-service\' target=\'_blank\'>Terms & conditions</a> ' +
            'and <a href=\'https://openfabric.ai/privacy-policy\' target=\'_blank\'>Privacy policy</a>',
        'login': 'Sign-in',
        'errors': {
            'email': {
                'required': 'The email is required',
                'email': 'The email is invalid',
                'exists': 'The email is already registered',
                'minlength': 'This value is too short',
            },
            'password': {
                'required': 'The password is required',
                'minlength': 'This value is too short. Please enter at least 8 characters.',
                'maxlength': 'Please limit your entry to 64 characters or less.',
            },
            'password_confirm': {
                'required': 'The password confirmation is required',
                'unmatched': 'The password confirmation does not match'
            },
            'first_name': {
                'pattern': 'The entered value does not appear as a valid',
                'minlength': 'This value is too short.',
                'required': 'This value is required',
            },
            'last_name': {
                'pattern': 'The entered value does not appear as a valid. Use only numbers',
                'minlength': 'This value is too short.',
                'required': 'This value is required',

            },
            'phone': {
                'pattern': 'The entered value does not appear as a valid',
                'maxlength': 'This value is too long.',
                'required': 'This value is required',
            },
            'invalid_confirm_terms': {
                'required': 'Must agree terms and conditions'
            },
            'all': {
                'minlength': 'This value is too short.',
                'required': 'This value is required',
            },
        }
    },
    'recover': {
        'box_title': 'Recover Your Account',
        'box_subtitle': 'Please enter your email to search for your account.',
        'recover_account': 'Reset',
    },
    'microphone': {
        'record': '[EN]Record Audio',
        'save': '[EN]Save Audio'
    },
    'navigation': {
        'app': 'App',
        'marketplace': 'AI Marketplace',
        'organization': 'Organizations',
        'platform': 'Platform',
        'my-executions': 'Installed apps',
        'add-app': 'Add Your APP',
        'rankings': 'Rankings',
        'system': {
            'user_management': 'Manage users',
            'organizations': 'Manage organizations',
            'tags': 'Manage tags',
            'categories': 'Manage categories',
            'workers': 'Manage infrastructure providers',
            'clear_cache': 'Clear caches'
        },
        'logout': 'Logout',
        'clear_cache': '',
        'dashboard': 'Dashboard',
        'home': 'Home',
        'profile': 'Profile',
        'missions': 'Missions',
        'leaderboard': 'Leaderboard',
        'users': 'Users',
        'statistics': 'Statistics',
        'tools': 'Tools',
        'validation': 'Validation',
        'validate_rewards': 'Validate Rewards',
        'tracking': 'Tracking',
        'emails': 'Emails',
        'redeem_gems': 'Redeem Gems',
        'settings': 'Settings',
    },
    'quick_links': {
        'title': 'Quick Links',
        'get_started': 'Get started with Openfabric',
        'developer': 'Openfabric developer',
        'funds': 'Add funds to Openfabric',
        'doc': 'Documentation',
        'report': 'Report a problem',
        'help_center': 'Help center'
    },
    'select': {
        'yes': 'Yes',
        'no': 'No',
        'true': 'True',
        'false': 'False',
        'active': 'Active',
        'inactive': 'Inactive',
        'open': 'Open',
        'closed': 'Closed'
    },
    'user': {
        'create_user_title': 'Users',
        'create_user': '',
        'page_title': 'Users',
        'email': 'Email',
        'password': 'Password',
        'first_name': 'First name',
        'last_name': 'Last name',
        'twitter_username': 'Twitter handle',
        'telegram_username': 'Telegram username',
        'discord_username': 'Discord username',
        'roles': 'Roles',
        'public_key': 'Public key',
        'verify_title': 'Verify Your email',
        'verify_message': 'We\'ve sent a verification link to your email. Click on it to verify your email account! <br> If you don\'t see it, you may need to <strong>check your spam</strong> folder.',
        'ok_button': 'Ok',
        'wallet_connect': 'Connect',
        'wallet_skip': 'Skip',
        'wallet_title': 'Connect Your Metamask Wallet',
        'wallet_details': 'Connect the wallet where you will get your Rewards. Prizes worth up to $5000 USD!',
        'wallet_details_mobile': 'Please use desktop browser to connect to your metamask wallet!',
        'user_no_login_title': 'Sign in or create an account'
    },
    'validation': {
        'default': 'This value seems to be invalid.',
        'mandatory_field': 'This value is required',
        'required': 'This value is required',
        'email': 'This value should be a valid email.',
        'unique': 'This value is not unique',
        'pattern': 'This value seems to be invalid.',
        'min': 'This value should be greater than or equal to %s.',
        'max': 'This value should be lower than or equal to %s.',
        'minlength': 'This value is too short. It should have %s characters or more.',
        'maxlength': 'This value is too long. It should have %s characters or fewer.',
        'length': 'This value length is invalid. It should be between %s and %s characters long.',
        'equalto': 'This value should be the same.',
        'upload_size': 'Sorry, the file you uploaded is too large. Please choose a file that is 5MB or smaller',
    },
    'search': {
        'placeholder': 'Search for missions here...',
    },
    'list': {
        'no_data_missions_fav': 'No favorite mission',
        'no_data_missions': 'Not available yet … stay tuned !',
        'no_data_leaderboard': 'Not avilable yet … stay tuned !',
    },
    'confirm': {
        'delete_missions': {
            'title': 'Delete current mission?',
            'details': 'Are your sure you want to delete this mission?',
            'ok_button': 'Delete',
            'cancel_button': 'Cancel'
        },
        'disconnect_wallet': {
            'title': 'Disconnect wallet?',
            'details': 'Are your sure you want to disconnect your wallet?',
            'ok_button': 'Disconnect',
            'cancel_button': 'Cancel'
        },
        'users': {
            'title': 'Reinvite inactive users',
            'details': 'Are your sure you want to start this process?',
            'ok_button': 'Ok',
            'cancel_button': 'Cancel'
        },
        'no_data_missions': 'Not available yet … stay tuned !',
        'email': {
            'title': 'Confirm send email',
            'details': 'Are your sure you want to start this process?',
            'ok_button': 'Ok',
            'cancel_button': 'Cancel'
        },
        'web3': {
            'title': 'Update wallet address?',
            'details': 'Are your sure you want to update your saved wallet address?',
            'ok_button': 'Ok',
            'cancel_button': 'No'
        },
    },
    'dashboard': {
        'welcome': 'Earn GEMs by Contributing to Your Favorite AI Community',
        'join': 'Join 130k+ people exploring AI every day with us',
        'start': 'Start with these introductory missions',
        'explore': 'Explore more missions',
        'rewards': 'Rewards',
        'no_reward': 'No rewards available now, stay tuned..',
    },
    'mission_assignment': {
        'mission_detail': 'Mission Detail',
        'about': 'Steps',
        'submission': 'Complete mission',
        'upload_generic_error': 'There are incomplete required fields.',
        'view_details': 'View Details',
    },
    'mission': {
        'details': 'Details',
        'steps': 'Steps',
        'mission_finished': 'of the mission is finished.',
        'about': 'About the mission',
        'assignments': 'Mission assignments',
        'mission': 'Mission',
        'entries': 'Entries',
    },
    'ai_abricators': {
        'name': 'Openfabric AI Pioneers',
        'more': 'more AI fabricators'
    },
    'gallery': {
        'heading': 'Community Gallery',
        'submit_prompt': 'Try it yourself',
        'submit_creation': 'Submit your creation',
        'create_gallery_title': 'Submit a gallery entry',
        'prompt_used': 'Prompt used to generate your creation',
    }
}
