import { KeyValue } from './../../../../core/model/ontology/model';
import { MissionEngagement } from './../../../../core/model/mission-engagement';
import { MissionCompletionStatService } from './../../../../core/service/crud/impl/mission-completion-stat.service';
import { MissionStep } from './../../../../core/model/mission-step';
import { MissionAssignment, MissionAssignmentStatus } from './../../../../core/model/mission-assignment';
import { AuthService } from './../../../../core/service/auth.service';
import { FilterOperator } from './../../../../core/service/crud/search.service';
import { MissionService } from './../../../../core/service/crud/impl/mission.service';
import { PaginateCriteria } from './../../../../core/service/crud/paginate.service';
import { MissionAssignmentService } from './../../../../core/service/crud/impl/mission-assignment.service';
import { Component, Input, OnInit } from '@angular/core';
import { MissionStepService } from './../../../../core/service/crud/impl/mission-step.service';
import { MissionEngagementService } from './../../../../core/service/crud/impl/mission-engagement.service';
import { Mission } from './../../../../core/model/mission';

@Component({
  selector: 'of-mission-assignment-explore',
  templateUrl: './mission-assignment-explore.component.html',
  styleUrls: ['./mission-assignment-explore.component.scss'],
  providers: [
    MissionAssignmentService,
    MissionService,
    MissionStepService,
    MissionCompletionStatService,
    MissionEngagementService
  ]
})
export class MissionAssignmentExploreComponent implements OnInit {

  @Input() missionId: string;
  @Input() excludeAssignmentId: string;

  isLoading = true;

  public myAssignments: MissionAssignment[] = [];
  public missionSteps: MissionStep[] = [];

  public missionAssignmentsToDisplay: MissionStep[] = [];

  public assignments: MissionAssignment[] = [];
  public myMissionIds: string[] =[];
  public missions: Mission[] =[];
  
  public missionEngagements: KeyValue<MissionEngagement> = {};

  constructor(
    private missionAssignmentService: MissionAssignmentService,
    private missionService: MissionService,
    private missionStepService: MissionStepService,
    private authService: AuthService,
    private missionCompletionStatService: MissionCompletionStatService,
    public missionEngagementService: MissionEngagementService,
  ) { }

  async ngOnInit(): Promise<void> {

    await this.getTopMissionSteps();

    await this.getMissionAssignmentToDisplay();

    await this.exploreMissions();

    this.isLoading = false;

  }

  async getTopMissionSteps(): Promise<void> {
    const data = await this.missionStepService.findByMissionIdAndNotHidden(this.missionId);
    
    this.missionSteps = data;
    await this.setUserAssignments();
  }

  private async setUserAssignments(): Promise<void> {
    const ids = [];

    this.missionSteps.forEach(x => {
        let id = ids.find(a => a === x.id);

        if (!id) { ids.push(x.id) }

        if (x.unlockingStep) {
            id = ids.find(a => a === x.unlockingStep.id);
            if (!id) { 
                ids.push(x.unlockingStep.id) 
            }
        }
    });

    if (ids.length > 0) {
        this.assignments = await this.missionAssignmentService.findUserMissionAssignments(this.authService.getUserId(), ids);
     }
  }

  async getMissionAssignmentToDisplay(): Promise<void> {

    this.missionSteps.forEach(x => {      
      if (this.missionAssignmentsToDisplay.length === 8) { return; }

      const found = this.assignments.find(a => a.missionStepId === x.id);
      if (found) {
        if (found.id === this.excludeAssignmentId) { return; }
        if (found.status !== MissionAssignmentStatus.NOT_STARTED) { return; }
        this.missionAssignmentsToDisplay.push(x);
      } else {
        this.missionAssignmentsToDisplay.push(x);
      }
    });
  }

  private async exploreMissions(): Promise<void> {
    await this.getMyMissionIds();
    await this.getMissionsToDisplay();
    await this.setMissionEngagement();
  }

  private async getMyMissionIds(): Promise<void> {
    const criteria = new PaginateCriteria(1000, 1);

    criteria.fields = { missionCompletionStat: 'missionId' }

    criteria.addFilter('missionCompletionStat.userId', FilterOperator.EQUALS, this.authService.getUserId());
    criteria.addFilter('missionCompletionStat.completed', FilterOperator.EQUALS, true);

    const data = await this.missionCompletionStatService.findAll(criteria).toPromise();

    this.myMissionIds = data.getModels().map(x => x.missionId);
  }

  private async getMissionsToDisplay(): Promise<void> {
    const criteria = new PaginateCriteria(8, 1);
    criteria.include = 'unlockingMission';
    criteria.addSort('updatedAt', 'desc');
    criteria.addNotInFilter('mission.id', this.myMissionIds);
    criteria.addFilter('mission.hidden', FilterOperator.EQUALS, false);

    const data = await this.missionService.findAll(criteria).toPromise();

    this.missions = data.getModels()

  }

  private async setMissionEngagement(): Promise<void> {
    const engagements = await this.missionEngagementService.findUserMissionEngagements();
    engagements.forEach(engagement => {
        this.missionEngagements[engagement.missionId] = engagement;
    });
  }
}
