import {User} from '../../model/user';
import {AuthService} from '../../service/auth.service';
import {PaginateCriteria} from '../../service/crud/paginate.service';
import {FilterOperator} from '../../service/crud/search.service';
import {MissionCompletionStatService} from './../../service/crud/impl/mission-completion-stat.service';
import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'of-mission-completed-by-users',
    templateUrl: './mission-completed-by-users.component.html',
    styleUrls: ['./mission-completed-by-users.component.scss'],
    providers: [
        MissionCompletionStatService
    ]
})
export class MissionCompletedByUsersComponent implements OnInit {

    @Input() missionId: string;

    users: User[] = [];

    isLoading = false;

    questers = 0;

    isLoggedIn = false;

    constructor(
        private missionCompletionStatService: MissionCompletionStatService,
        private authService: AuthService
    ) {
    }

    async ngOnInit() {
        this.isLoggedIn = this.authService.isLoggedIn();

        if (!this.isLoggedIn) {
            return;
        }

        await this.getUserWhoCompletedThisMission();
    }

    async getUserWhoCompletedThisMission(): Promise<void> {
        this.isLoading = true;
        const criteria = new PaginateCriteria(30, 1);

        criteria.fields = { 
            missionCompletionStat: 'user',
            user: 'id,image,firstName,lastName'
         }

        criteria.include = 'user';

        criteria.addFilter('missionCompletionStat.missionId', FilterOperator.EQUALS, this.missionId);

        try {
            const data = await this.missionCompletionStatService.findAll(criteria).toPromise();
            this.users = data.getModels().map(x => x.user);
            this.questers = data.getMeta().meta.page.totalRecords;
        } catch (error) {
            this.users = [];
        }

        this.isLoading = false;
    }

}
